import { Injectable, NgZone } from '@angular/core';
import { LoadDataService } from './load-data.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { words_en, words_he } from '../app.translator';
import { AuthState } from '../enums';
import { AlertService } from './alert.service';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { GoogleAuthService } from './google-auth.service';
import { ParkingsService } from './parkings.service';
import { EmployeeType, UserService } from './user.service';
import * as moment from 'moment';
import { ReplaySessionService } from './replay-session.service';
import { DataService } from './data.service';

@Injectable()
export class AppStartService {
  appStartBool = false;
  creditCardExpAlert: boolean;
  show = false;

  constructor(
    private _userService: UserService,
    private _parkingsService: ParkingsService,
    private _loadData: LoadDataService,
    private _googleAuthService: GoogleAuthService,
    private translate: TranslateService,
    private _alertService: AlertService,
    private _router: Router,
    private zone: NgZone,
    private replaySessionService: ReplaySessionService,
    private dataService: DataService,
  ) {
    // enet on app init:
    this._googleAuthService.afterLogout = () => {
      this.dataService.permissions = null;
    };

  }

  // enet every time of user login:
  async init() {
    await this._googleAuthService.initGapiForBrowserOnly();
    this.initData();
  }

  finishLoad() {
    const loadingAlert = document.getElementById('loadingAlert');
    if (loadingAlert) {
      loadingAlert.remove();
    }
    this._loadData.finishLoadData();
  }

  async initData() {
    try {
      const accessTokenKey = AppGlobals.LOCAL_STORAGE.AccessTokenObject;
      const atObj = JSON.parse(localStorage.getItem(accessTokenKey) || '');
      this.replaySessionService.sendEventToReplaySession(
        'debug atObj',
        atObj
      );

      if (atObj) {
        const now = moment().format();
        const duration = moment.duration(moment(atObj.timeStamp).diff(now));
        const hours = Math.abs(duration.asHours());
        if (hours >= 1) {
          gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse()
            .then((response: gapi.auth2.AuthResponse) => {
              this._googleAuthService.handleGoogleLoginResponse(response.access_token, 'google', (token) => {
                this.replaySessionService.sendEventToReplaySession(
                  'refreshed token',
                  { token }
                );
                this.ValidateUser();
              });
            })
            .catch((error) => {
              this.replaySessionService.sendEventToReplaySession(
                'refresh token failure',
                { atObj, error, hours }
              );
              this.ValidateUser();
            });
        } else {
          this.ValidateUser();
          this.replaySessionService.sendEventToReplaySession(
            'access token',
            atObj
          );
        }
      } else {
        this.replaySessionService.sendEventToReplaySession(
          'no access token',
          null
        );
      }
    } catch (error) {
      localStorage.setItem(
        AppGlobals.LOCAL_STORAGE.AuthState,
        AuthState.NOT_SET.toString()
      );
      console.log(`error in trying loging: ${error}`);
    } finally {
      console.log('getUser finally appStart');
      this.finishLoad();
    }
  }

  ValidateUser = async (): Promise<void> => {
    const userDetails = await this.dataService.getUser()
    if (userDetails) {
      if ((!userDetails?.userID || !userDetails?.userIID) || this._userService.userType === null) {
        await new Promise<void>(resolve => {
          this.zone.run(() => {
            this._googleAuthService.logout(resolve)
          });
        })
        this._alertService.error('משתמש לא מורשה', true);
      }
      if (
        this._userService.userType === EmployeeType.MaintenanceAndWash
      ) {
        this._router.navigate(['/modeMenu']);
      } else {
        this._router.navigate(['/menu']);
      }
      this.dataService.initData();

      // this._parkingsService
      //   .loadAllParkings(true)
      //   .subscribe(async (parkings: Parking[]) => {
      //     this._parkingsService.OriginalParkings = parkings;

      //     this._parkingsService.loadWashCarFrequencies()
      //       .subscribe((washFrequencies) => {
      //         this.fillFrequnciesInfoOrDefaultOnParkings(washFrequencies);
      //         this._parkingsService.applyFilterParkingsByUserMode();
      //       });
      //   });
    }
  }

  loadTranslators() {
    this.translate.setTranslation('en', words_en, true);
    this.translate.setTranslation('he', words_he, true);
  }

}

<button class="close-btn" (click)="closeModal()">X</button>
<div class="carInRent">
  <h4 class="title-popup" translate>
    <br />{{ 'ORDER_NUM' | translate }}: {{ order.id }}
  </h4>

  <b>{{ 'CAR_NUM' | translate }}: {{ order.car.carNumber }}</b>
  <ul>
    <li>
      {{ 'CAR_AS_OF' | translate }}:
      <a>{{ order.startTime | customDate: 'dd/MM/yyyy - HH:mm' }}</a>
    </li>
    <li></li>
    <li>
      {{ 'UP_TO' | translate }}:
      <a> {{ order.endTime | customDate: 'dd/MM/yyyy - HH:mm' }}</a>
    </li>
    <li></li>
    <li>
      {{ 'RETURN_STATION' | translate }}: <a>{{ addressName }}</a>
    </li>
    <li>
      {{ 'OFFICE_ORDER_TYPE' | translate }}:
      <a>{{ OfficeOrderTypeDescriptionHeEnum[order['officeDriveType']] }}</a>
    </li>
    <li *ngIf="order['officeDriveReason']">
      {{ 'OFFICE_ORDER_REASON' | translate }}:
      <a>{{ order['officeDriveReason'] }}</a>
    </li>
    <br />
    <div class="class-title tabs">
      <button
        [disabled]="!enableGetUnlockCode"
        (click)="getUnlockCode()"
        translate
      >
        {{ getCode }}
      </button>
      <button
        *ngIf="order.startParking.hasGateOpener"
        [disabled]="!enableOpenGate"
        (click)="openGate()"
        translate
      >
        OPEN_GATE
      </button>
      <button
        [disabled]="order.realDatetimeStart"
        [ngClass]="{ 'active-tab': isShowMapWithDirection }"
        (click)="toggleShowMapWithDirection()"
        translate
      >
        WHERE_CAR
      </button>
    </div>
  </ul>
  <p></p>
  <p></p>

  <div class="class-title tabs">
    <button
      class="doors-div"
      [disabled]="!enableOpenDoors"
      (click)="performActionOnDoors(true)"
    >
      <i class="fa fa-unlock" aria-hidden="true"></i
      >{{ 'OPEN_DOOR' | translate }}
    </button>

    <p class="message">
      <i class="fa fa-lock" aria-hidden="true"></i>
      {{ 'LOCK_MESSAGE' | translate }}<br />
    </p>
    <p style="color: purple; font-weight: bold">
      {{ 'LOCK_MESSAGE2' | translate }}
    </p>
  </div>

  <div
    style="
      display: table;
      margin: 0 auto;
      margin-bottom: 15px;
      margin-top: 15px;
    "
  >
    <div class="waze-navigator" (click)="navigateToCar()">
      נווט באמצעות
      <img alt="waze" src="https://img.icons8.com/color/22/000000/waze.png" />
    </div>
  </div>

  <div class="row class-title tabs">
    <button
      [ngClass]="{ 'active-tab': isShowFinishRent }"
      [ngClass]="showMaintenanceOptions ? 'col-xs-3' : 'col-xs-4'"
      (click)="toggleFinishRent()"
      translate
    >
      END_RENT
    </button>
    <button
      [ngClass]="{ 'active-tab': isShowSendPictures }"
      [ngClass]="showMaintenanceOptions ? 'col-xs-3' : 'col-xs-4'"
      (click)="toggleSendPictures()"
      translate
    >
      תמונות
    </button>
    <button
      *ngIf="showMaintenanceOptions"
      [ngClass]="{ 'active-tab': isShowMaintenance }"
      [ngClass]="showMaintenanceOptions ? 'col-xs-3' : 'col-xs-4'"
      (click)="toggleMaintenance()"
      translate
    >
      תחזוקה
    </button>
    <button
      [ngClass]="{ 'active-tab': isShowLateReport }"
      [ngClass]="showMaintenanceOptions ? 'col-xs-3' : 'col-xs-4'"
      (click)="toggleShowLateReport()"
      translate
    >
      הארכת זמן
    </button>

    <ng-template [ngIf]="isShowExtendRent">
      <div class="tabOption col-xs-12">
        <br />
        <div class="container-fluid">
          <p>לחץ כדי להאריך את זמן ההשכרה ב:</p>
          <div class="row">
            <div class="col-xs-4">
              <button (click)="addMinutesToRent(60)">60 דקות</button>
            </div>
            <div class="col-xs-4">
              <button (click)="addMinutesToRent(30)">30 דקות</button>
            </div>
            <div class="col-xs-4">
              <button (click)="addMinutesToRent(15)">15 דקות</button>
            </div>
          </div>
        </div>
        <hr />
        <div *ngIf="order.period === 3">
          <button (click)="extendRentHoursOrDays(1)" translate>
            הארכת שעות
          </button>
          <button (click)="extendRentHoursOrDays(2)" translate>
            הארכת ימים
          </button>
        </div>

        <div *ngIf="hoursOrDays === 1 || order.period !== 3">
          <h4 translate>REPORT_MESSAGE_EXT3</h4>
          <a (click)="addToHoursToAdd()">
            <i
              class="fa fa-chevron-circle-up littleChangeTime"
              aria-hidden="true"
            ></i>
          </a>
          <input
            disabled
            [(ngModel)]="hoursToAdd"
            type="number"
            class="smallFormatInput"
          />
          <a (click)="removeFromHoursToAdd()">
            <i
              class="fa fa-chevron-circle-down littleChangeTime"
              aria-hidden="true"
            ></i>
          </a>
        </div>

        <div *ngIf="hoursOrDays === 2">
          <h4 translate>בכמה ימים תרצה להאריך את זמן ההשכרה?</h4>
          <a (click)="addToDaysToAdd()">
            <i
              class="fa fa-chevron-circle-up littleChangeTime"
              aria-hidden="true"
            ></i>
          </a>
          <input
            disabled
            [(ngModel)]="daysToAdd"
            type="number"
            class="smallFormatInput"
          />
          <a (click)="removeFromDaysToAdd()">
            <i
              class="fa fa-chevron-circle-down littleChangeTime"
              aria-hidden="true"
            ></i>
          </a>
        </div>

        <div class="addHoursAlert" *ngIf="startCatchedTime">
          <p>
            {{ 'TAKEN' | translate }}:
            {{ startCatchedTime | customDate: 'dd/MM/yyyy - HH:mm' }}
          </p>
          <p *ngIf="isExtendByMinutes">
            {{ 'EXTENSION_MESSAGE' | translate }} {{ hoursToAdd }}
            {{ 'HOURS' | translate }}
          </p>
          <p *ngIf="!isExtendByMinutes">
            {{ 'EXTENSION_MESSAGE2' | translate }} {{ hoursToAdd }}
            {{ 'HOURS' | translate }}
          </p>
        </div>
        <div>
          <button (click)="extendRent()" translate>OK</button>
        </div>
      </div>
    </ng-template>
    <ng-template [ngIf]="isShowMaintenance">
      תחזוקה
      <form [formGroup]="_maintenanceForm">
        <div style="border: 2px solid purple">
          <h3 style="font-weight: bold">בחר הכל</h3>
          <div class="container-fluid">
            <div class="row">
              <div class="col-xs-6">
                <input
                  [id]="2"
                  type="radio"
                  name="selectAll"
                  [value]="2"
                  [(ngModel)]="selectAllVal"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="selectAll()"
                /><label for="rad1">לא תקין</label>
              </div>
              <div class="col-xs-6">
                <input
                  [id]="1"
                  type="radio"
                  name="selectAll"
                  [value]="1"
                  [(ngModel)]="selectAllVal"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="selectAll()"
                /><label for="rad1">תקין</label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="form1"
          formArrayName="Array"
          *ngFor="let arrayItem of _arrayItems; let i = index"
          style="border: 2px solid purple"
        >
          <h4>{{ arrayItem.item }}:</h4>
          <div class="container-fluid">
            <div class="row">
              <div class="col-xs-6">
                <input
                  [id]="arrayItem.id"
                  type="radio"
                  id="rad2"
                  [value]="2"
                  [(ngModel)]="_arrayItems[i].value"
                  [ngModelOptions]="{ standalone: true }"
                /><label for="rad1">לא תקין</label>
              </div>
              <div class="col-xs-6">
                <input
                  [id]="arrayItem.id"
                  type="radio"
                  id="rad1"
                  [value]="1"
                  [(ngModel)]="_arrayItems[i].value"
                  [ngModelOptions]="{ standalone: true }"
                /><label for="rad1">תקין</label>
              </div>
            </div>
          </div>
        </div>
        <div class="center-div" style="margin-top: 10px">
          <textarea
            name="Notes"
            rows="4"
            [(ngModel)]="_maintenanceNotes"
            [ngModelOptions]="{ standalone: true }"
          ></textarea>
        </div>
        <div class="center-div" style="margin-top: 10px">
          <label
            >האם לסיים את ההזמנה
            <input
              type="checkbox"
              [(ngModel)]="_finishOrder"
              [ngModelOptions]="{ standalone: true }"
            />
          </label>
          <button
            type="submit"
            class="blue-btn-class"
            (click)="finishcarMaintenance()"
            style="width: 100%; margin: 0 auto"
          >
            שלח
          </button>
        </div>
      </form>
    </ng-template>
    <ng-template
      [ngIf]="
        !isShowMapWithDirection &&
        !isShowLateReport &&
        !isShowExtendRent &&
        !isShowFinishRent &&
        !isShowSendPictures &&
        !isShowMaintenance
      "
    >
      <div class="tabOption col-xs-12">
        <div>
          <div class="text-center">
            <img src="{{ getCarsImages(order.car.carType).IMG }}" alt="" />
          </div>
          <h2 class="h2-car-in-rent">
            <span style="font-size: 14px">{{ order.car.carType }} </span>
            <img
              style="width: 20px"
              src="{{ getCarsImages(order.car.carType).LOGO }}"
              alt=""
            />
            <span style="font-size: 14px"></span>
          </h2>
        </div>
      </div>
    </ng-template>
    <ng-template [ngIf]="isShowMapWithDirection">
      <div class="tabOption col-xs-12">
        <agm-map [latitude]="lat" [longitude]="lng">
          <agm-direction
            [renderOptions]="renderOpts"
            [markerOptions]="markerOpts"
            [origin]="dir.origin"
            [destination]="dir.destination"
            [travelMode]="TravelMode.WALKING"
          ></agm-direction>
        </agm-map>
      </div>
    </ng-template>
    <ng-template [ngIf]="isShowLateReport">
      <div class="tabOption col-xs-12">
        <div class="container-fluid">
          <p>לחץ כדי להאריך את זמן ההשכרה ב:</p>
          <div class="row">
            <div class="col-xs-4">
              <button (click)="addMinutesToRent(60)">60 דקות</button>
            </div>
            <div class="col-xs-4">
              <button (click)="addMinutesToRent(30)">30 דקות</button>
            </div>
            <div class="col-xs-4">
              <button (click)="addMinutesToRent(15)">15 דקות</button>
            </div>
          </div>
          <br />
          <div *ngIf="showMaintenanceOptions">
            <p>הארכת זמן ההשכרה בשעות:</p>
            <div class="row">
              <div>
                <h4 translate>REPORT_MESSAGE_EXT3</h4>
                <a (click)="addToHoursToAdd()">
                  <i
                    class="fa fa-chevron-circle-up littleChangeTime"
                    aria-hidden="true"
                  ></i>
                </a>
                <input
                  disabled
                  [(ngModel)]="hoursToAdd"
                  type="number"
                  class="smallFormatInput"
                />
                <a (click)="removeFromHoursToAdd()">
                  <i
                    class="fa fa-chevron-circle-down littleChangeTime"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
              <div>
                <button (click)="extendRent()" translate>OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template [ngIf]="isShowFinishRent">
      <div class="tabOption">
        <br /><br />
        <h4>
          {{ 'FINISH_MESSAGE' | translate }} <br />
          {{ 'FINISH_REPORT' | translate }}
        </h4>

        <br />
        <div *ngIf="showWashOptions">
          <button (click)="finishRent(false)" translate>FINISH_REPORT2</button>
          <button (click)="finishRent(true)" translate>FINISH_REPORT3</button>
        </div>
        <div *ngIf="showMaintenanceOptions">
          <button (click)="finishRent()" translate>FINISH_REPORT1</button>
        </div>
      </div>
    </ng-template>
    <ng-template [ngIf]="isShowSendPictures">
      <div class="row" style="margin: 3px">
        <div class="imagesDescription">
          <div class="col-xs-12 damageDiv">
            <div>
              <div>
                <image-upload
                  dir="ltr"
                  [style]="customStyle"
                  [buttonCaption]="'העלה תמונות'"
                  [dropBoxMessage]="'גרור לכאן תמונות'"
                  [clearButtonCaption]="'הסר הכל'"
                  [extensions]="['jpeg', 'png']"
                  (uploadFinished)="onUploadFinished($event, 'front')"
                  (removed)="onRemoved($event)"
                ></image-upload>
              </div>

              <div>
                <div class="imagesDescription">
                  <div
                    class="col-xs-6 damageDiv"
                    *ngFor="let image of images; let i = index"
                  >
                    <div style="margin-top: -3px">
                      <div class="imageDiv" style="border: black 2px solid">
                        <img width="100%" class="col-xs-12" [src]="image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div
            class="col-xs-10 col-xs-offset-1"
            style="margin-top: 25px; margin-bottom: 10px"
          >
            <button class="blue-btn-class" (click)="sendImage()">
              שלח תמונות
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <hr />
</div>

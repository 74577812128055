import {
  Component,
  OnInit,
  ChangeDetectorRef,
  forwardRef,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { LatLngBounds } from '@agm/core';
import { GlobalService } from '../../services/global.service';
import * as moment from 'moment';
import { IrregularOrderService } from '../../services/irregular-order.service';
import { of, Subscription } from 'rxjs';
import { AppStartService } from '../../services/app-start.service';
import { CarAvailabilityPipe } from '../../pipes/car-availability.pipe';
import { MatDialog } from '@angular/material/dialog';
import { SelectOfficeDriveTypeComponent } from '../../modals/select-office-drive-type/select-office-drive-type.component';
import { ReportMaintenanceComponent } from '../../modals/report-maintenance/report-maintenance.component';
import { SearchByLocationOptions } from '../../enums';
import PageTitle from '../../models/page-title';
import { ErrorResponse } from '../../models/error-response';
import { MapLocation } from '../../models/map-location';
import { EmployeeOrder, Order } from '../../models/order';
import { Parking, ParkingOnMap } from '../../models/parking';
import { CarAvailabilitySuggestionPipe } from '../../pipes/car-availability-suggestion.pipe';
import { AlertService } from '../../services/alert.service';
import { GoogleMapsService } from '../../services/google-maps.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { OrderService } from '../../services/order.service';
import { ParkingsService } from '../../services/parkings.service';
import { UserMode, UserService } from '../../services/user.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';
import { ModalContext } from '../../modals/base-modal/base-modal.component';
import { catchError, map } from 'rxjs/operators';
import { CarsInRent } from '../../services/cars-in-rent.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectTimeComponent } from '../select-time/select-time.component';
import { DataService } from '../../services/data.service';
import { CarAvailability } from '../../pipes/time-slots.pipe';

enum LastWashEnum {
  LESS_OR_EQUAL_TO_7_DAYS,
  LESS_OR_EQUAL_TO_14_DAYS,
  MORE_THEN_14_DAYS,
  UNKNOWN,
}

enum LastDateIndicator {
  Red,
  Yellow,
  Green,
  Gray,
}

export interface MapOptions {
  location?: MapLocation;
  zoom: number;
  markersIcon: string;
  gestureHandling?: 'cooperative' | 'greedy' | 'none';
}

@Component({
  selector: 'app-stations-in-map',
  templateUrl: './stations-in-map.component.html',
  styleUrls: ['./stations-in-map.component.css'],
})
export class StationsInMapComponent implements OnInit, OnDestroy {
  public CarAvailability: typeof CarAvailability = CarAvailability;


  LastWashEnum = LastWashEnum;
  isOpen = false;
  UserMode = UserMode;
  carCategories = <
    { name: string; id: number; isSelected: boolean }[]
    >[
      { name: '4 מקומות', id: 5, isSelected: true },
      { name: 'רגיל (קטן)', id: 1, isSelected: true },
      { name: 'משפחתי', id: 2, isSelected: true },
      { name: 'משפחתי פלוס', id: 3, isSelected: true },
      { name: '7 מקומות', id: 6, isSelected: true },
      { name: '7 קיא קרניבל', id: 12, isSelected: true },
    ];

  officeDriveTypes = <
    { name: string; id: number }[]
    >[
      { name: 'מוסך', id: 0 },
      { name: 'הרשמה', id: 1 },
      { name: 'תיקונים', id: 2 },
      { name: 'שונות', id: 3 },
      { name: "פנצ'ר", id: 4 },
      { name: 'שטיפה', id: 5 },
      { name: 'טסט', id: 6 },
      { name: 'תדלוק', id: 7 },
      { name: 'תחזוקה', id: 8 },
    ];

  parkings: Parking[];
  defoultMapZoom = 17;

  mapOptions: MapOptions = {
    zoom: this.defoultMapZoom,
    markersIcon: 'assets/images/icon_pointer_citycar.png',
    gestureHandling: 'greedy',
  };

  previosOrder: Order;

  currentRadiusOfParkings: number;
  currentParkingsCenter: MapLocation;

  defaultMapLocation: MapLocation = {
    lat: 32.08593785548084,
    lon: 34.83792543411255,
  };

  currentZoom = 17;
  showZoomAlert = true;
  currentAddressName: string;
  currentLocation: MapLocation;

  selectedParking: ParkingOnMap | null;
  selectedParkingError: string;

  selectedCarIndex = 0;
  isShowCarDetails: boolean;

  MILI_SECONDES_PER_MINUTES = 60 * 1000;

  get pageTitle(): PageTitle {
    return {
      title: 'STATION_MAP',
      previousUrl: '/menu',
    };
  }

  addOrderNowButtonString = this.isInMaintenanceMode ? 'תחזק' : 'שטוף';

  get selectedCarCategories() {
    return this.carCategories.filter(c => c.isSelected).map(c => c.id);
  }
  isShowCarCategorySort = false;
  isShowCarCategorySortButton = false;

  get isInMaintenanceMode() {
    return this._userService.userMode === UserMode.Maintenance;
  }

  isShowEditTime = false;
  reloadParkingInterval: any;
  showAllParkings = false;

  private _isSelectAllCategories = true;
  public get isSelectAllCategories() {
    return this._isSelectAllCategories;
  }

  public set isSelectAllCategories(v: boolean) {
    if (v !== this._isSelectAllCategories) {
      this.carCategories.forEach(c => c.isSelected = v);
    }
    this._isSelectAllCategories = v;
  }

  subscription: Subscription;
  invokeBoundsChangedEvent = true;

  eventFromChild(data: boolean) {
    console.log(data);
    this.selectedParking = null;
  }

  get isZoomGood() {
    return this.currentZoom >= 17;
  }

  get selectedCar() {
    if (!this.selectedParking?.carsDetails) {
      throw new Error('selectedParking is null');
    }
    return this.selectedParking.carsDetails[this.selectedCarIndex];
  }

  get selectedCarSuggestions(): string[] {
    if (!this.selectedCar) {
      throw new Error('selectedParking is null');
    }
    return this._carAvailabilitySuggestionPipe.transform(
      this.selectedCar.timeSlots
    );
  }

  get selectedCarAvailability(): string[] {
    if (!this.selectedCar) {
      throw new Error('selectedCar is null');
    }
    return this._carAvailabilityPipe.transform(this.selectedCar.timeSlots[0].used);
  }

  get pointerImg() {
    return AppGlobals.POINTER_IMAGES.CITY_CAR;
  }

  get pointerNotAvailable() {
    return AppGlobals.POINTER_IMAGES.CITY_CAR_RED;
  }

  get pointerNotCompletelyAvailable() {
    return AppGlobals.POINTER_IMAGES.CITY_CAR_ORANGE;
  }

  get isEditOrder() {
    return this._orderService.IsEditOrder;
  }

  get orderId() {
    return this._orderService.Order.id;
  }

  constructor(
    @Inject(forwardRef(() => AppStartService))
    private _appStart: AppStartService,
    private _router: Router,
    private _route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private _modalService: ModalService,
    public _orderService: OrderService,
    private _parkingsService: ParkingsService,
    private _googleMapsService: GoogleMapsService,
    private _loadingService: LoadingService,
    private _alertService: AlertService,
    private _globalService: GlobalService,
    private _carAvailabilitySuggestionPipe: CarAvailabilitySuggestionPipe,
    private _carAvailabilityPipe: CarAvailabilityPipe,
    private _irregularOrderService: IrregularOrderService,
    public _userService: UserService,
    public _dialog: MatDialog,
    private carsInRent: CarsInRent,
    private modalService: NgbModal,
    private dataService: DataService,
  ) {
    document.addEventListener('click',
      (e: MouseEvent): void => {
        const target = e.target as Element;
        if (
          !target ||
          target.id !== 'editDateBtn' &&
          target.id !== 'sortByCarTypeBtn' &&
          target.id !== 'littleChangeTime'
        ) {
          this.isShowCarCategorySort = false;
          this.isShowEditTime = false;
        }
      });
  }

  stopProposition(event) {
    event.stopImmediatePropagation();
  }

  async ngOnInit() {
    this.irregularOrder();
    this._orderService.Order.isIrregularOrder = false;
    this._orderService.isOrderChanged = true;
    this.previosOrder = this._globalService.copyObject(
      this._orderService.Order
    );

    this.setLocalTime();
    AppGlobals.PREVIOUS_URL = 'order/selectTime';

    this.parkings = this.dataService.permittedParkings;

    this._route.queryParams.subscribe(async params => {
      const parkingId: number = params['parkingId'];
      // check if there is selected station from route or from service:
      if (parkingId) {
        // select this parking
        const selectedParking = await this._parkingsService.getParkingById(parkingId);
        this.selectParking(selectedParking, true);
        this.cdRef.detectChanges();
      } else if (
        this._orderService.Order &&
        this._orderService.Order.startParking
      ) {
        this.selectParking(this._orderService.Order.startParking, true);
      } else {
        this.setDefoultLocation(this._orderService.searchByOption).subscribe();
        this.isShowCarCategorySortButton = true;
        this.reloadParkingInterval = setInterval(() => {
          console.log(this.isZoomGood);
          console.log(this.currentParkingsCenter);
          this._orderService.updateIfOrderForNow();
          this.reloadParkings()?.then(
            parkings => console.log(parkings),
            (error: ErrorResponse) => {
              if (error.CustomErrorMessage && error.CustomErrorMessage.length) {
                this._alertService.error(error.CustomErrorMessage);
              }
            }
          );
        }, this.MILI_SECONDES_PER_MINUTES * 2);
      }
    });
  }

  setLocalTime() {
    this.previosOrder.startTime = moment(this.previosOrder.startTime).toDate();
    this.previosOrder.endTime = moment(this.previosOrder.endTime).toDate();
  }

  ngOnDestroy() {
    this._appStart.show = true;
    this.cdRef.detach();
    console.log('ngOnDestroy');
    clearInterval(this.reloadParkingInterval);
  }

  selectAddress(location: MapLocation) {
    this.mapOptions.location = location;
  }

  selectParkingByIndex(selectedIndex: number) {
    this.selectParking(this.parkings[selectedIndex]);
  }

  selectParking(
    selectedParking: Parking,
    isSetParkingOnMap = false
  ) {
    this.selectedCarIndex = 0;

    console.log('selectedParking', selectedParking);
    if (selectedParking) {
      this.selectedParking = selectedParking as ParkingOnMap;
      // set location to the center of the map
      if (isSetParkingOnMap) {
        this.setMapCenter(selectedParking.earthLocation, false);
      }
      if (selectedParking.carsDetails?.length) {
        const car = this.selectedParking.carsDetails.find(
          c => c.availability === CarAvailability.FREE
        ) || this.selectedParking.carsDetails.find(
          c => c.availability === CarAvailability.OVERLAPPING
        );
        if (car) {
          this.selectedCarIndex = this.selectedParking.carsDetails.indexOf(car);
        }
      }
    }
  }

  isCarCategorySelected(parking: Parking): boolean {
    if (this.carCategories.every(c => c.isSelected === true)) {
      return true;
    }
    const sortedCarCategories = this.carCategories
      .filter(c => c.isSelected === true)
      .map(c => c.id);
    return sortedCarCategories.includes(parking.carsDetails[0].carCategory);
  }

  sortBycarCategory() {
    this.isShowCarCategorySort = false;
  }

  irregularOrder() {
    if (this.selectedParking) {
      const availability = this.selectedParking.carsDetails[0].availability;
      const beforeUsedTimeSlot =
        this.selectedParking.carsDetails[0].timeSlots[0].used.filter(
          t =>
            new Date(t.end) > this.previosOrder.startTime &&
            new Date(t.start) < this.previosOrder.startTime
        );

      const usedTimeSlot =
        this.selectedParking.carsDetails[0].timeSlots[0].used.find(
          r =>
            new Date(r.start) < this.previosOrder.endTime &&
            new Date(r.end) > this.previosOrder.startTime &&
            new Date(r.start) > this.previosOrder.startTime
        );

      if (
        this.selectedParking.carsDetails != null &&
        availability === CarAvailability.OVERLAPPING &&
        usedTimeSlot &&
        beforeUsedTimeSlot.length === 0
      ) {
        this._irregularOrderService.wantIrregularOrder(
          this.previosOrder,
          this.selectedParking
        );
        this._irregularOrderService
          .getIsWantIrregularOrder()
          .subscribe(r => {
            if (r === true) {
              this.openOrder();
            }
          });
      }
    }
  }

  openOfficeDrive() {
    if (!this.selectedParking) {
      throw new Error('No parking selected');
    }
    this._orderService.StartParking = this.selectedParking;
    this._orderService.Car =
      this.selectedParking.carsDetails[this.selectedCarIndex];
    console.log(this.officeDriveTypes);
    this._dialog
      .open(SelectOfficeDriveTypeComponent, {
        data: { officeDriveTypes: this.officeDriveTypes },
      })
      .afterClosed()
      .subscribe(data => {
        console.log(data);
        if (data !== undefined) {
          const ref = this.modalService.open(SelectTimeComponent, { centered: true });
          ref.result
            .then(() => this.createOfficeDrive(data.type.id, data.reason))
            .catch(error => console.log(error));
        }
      });
  }

  openOrder() {
    if (!this.selectedParking) {
      throw new Error('No parking selected');
    }
    this._orderService.StartParking = this.selectedParking;
    this._orderService.Car =
      this.selectedParking.carsDetails[this.selectedCarIndex];

    // check if order has changes:
    if (this.previosOrder === this._orderService.Order) {
      this._orderService.isOrderChanged = false;
    }
    this._router.navigate(['order/finishOrder']);
  }

  WashCarNow() {
    if (!this.selectedParking) {
      throw new Error('No parking selected');
    }
    const now = new Date(Date.now());
    this._orderService.StartParking = this.selectedParking;
    this._orderService.Car =
      this.selectedParking.carsDetails[this.selectedCarIndex];

    this._orderService.Order.startTime = new Date(
      now.setMinutes(now.getMinutes() + 1)
    );
    this._orderService.Order.endTime = new Date(
      now.setMinutes(now.getMinutes() + 20)
    );

    this.createOfficeDrive();
  }

  WashCarLater() {
    const ref = this.modalService.open(SelectTimeComponent, { centered: true });
    ref.result
      .then(() => {
        if (!this.selectedParking) {
          throw new Error('No parking selected');
        }
        this._orderService.StartParking = this.selectedParking;
        this._orderService.Car =
          this.selectedParking.carsDetails[this.selectedCarIndex];
        this.createOfficeDrive();
      })
      .catch(error => console.log(error));
  }

  private createOfficeDrive(
    p_officeDriveType: number | null = null,
    p_officedriveReason: number | null = null
  ) {
    let officeDriveType = p_officeDriveType;
    if (officeDriveType == null) {
      officeDriveType = this.SetOfficeDriveTypeByUserMode(this._userService.userMode);
    }
    this._orderService.Order.startTime.setSeconds(0);
    this._orderService.Order.endTime.setSeconds(0);
    const employeeOrder = new EmployeeOrder(
      true,
      officeDriveType,
      this._orderService.Order
    );
    if (p_officedriveReason) {
      employeeOrder.officeDriveReason = p_officedriveReason.toString();
    }
    this._orderService.changeCurrentOrder(employeeOrder);
    console.log(this._orderService.Order);
    const isUpdateOrder = this._orderService.Order && this._orderService.Order.id;
    console.log(isUpdateOrder);
    const saveOperation = isUpdateOrder ? this._orderService.updateOrder(true) : this._orderService.saveOrder(true);
    console.log(saveOperation);

    saveOperation
      .then((order: Order) => {
        console.log(order);
        this._orderService.changeCurrentOrder(new Order());
        this._alertService.success('פעולתך בוצעה');
        this._loadingService.stopLoading();
        setTimeout(() => {
          const minutes = this.isOrderBeginNow(order);
          if (Math.abs(minutes) < 5) {
            this._orderService.openCurrenOrder = order.id;
          }
          this.carsInRent.showCarInRent(order);
          this._router.navigate(['menu']);
        }, 500);
      })
      .catch((error: ErrorResponse | any) => {
        console.error(error);
        this._alertService.error(error?.error?.error);
      });
  }

  private SetOfficeDriveTypeByUserMode(userMode: UserMode) {
    switch (userMode) {
      case UserMode.Wash:
        return 5;
      case UserMode.Maintenance:
        return 8;

    }
  }

  private isOrderBeginNow(order: Order) {
    const now = moment(new Date());
    const _start = moment(order.startTime);
    const duration = moment.duration(now.diff(_start));
    const minutes = duration.asMinutes();
    console.log(minutes);
    return minutes;
  }

  emitChangeTime() {
    this.reloadParkings().then(
      () => {
        this.isShowEditTime = false;
      },
      (error: ErrorResponse) => {
        if (error.CustomErrorMessage && error.CustomErrorMessage.length) {
          this._alertService.error(error.CustomErrorMessage);
        }
      }
    );
  }

  zoomChange(zoom: number) {
    this.currentZoom = zoom;
    console.log(`current zoom: ${this.currentZoom}`);
  }

  boundsChange(event: LatLngBounds) {
    if (this.invokeBoundsChangedEvent) {
      const currentMapCenter = this.custToMapLocation(
        event.getCenter()
      );

      const currentMapRadius =
        this._googleMapsService.getDistanceFromLatLonInKm(
          this.custToMapLocation(event.getNorthEast()),
          this.custToMapLocation(event.getSouthWest())
        ) / 2;

      this.loadParking(currentMapCenter, currentMapRadius);
      this.invokeBoundsChangedEvent = false;
    }
  }

  async toggleShowAllParkings() {
    this.showAllParkings = !this.showAllParkings;
    console.log(this.showAllParkings);

    if (this.showAllParkings) {
      this.parkings = await this.dataService.getParkings();
    } else {
      this.parkings = this.dataService.permittedParkings;
    }
    this.reloadParkings().then(
      parkings => console.log(parkings),
      (error: ErrorResponse) => {
        if (error.CustomErrorMessage && error.CustomErrorMessage.length) {
          this._alertService.error(error.CustomErrorMessage);
        }
      }
    );
  }

  loadParking(
    currentMapCenter: MapLocation = this.defaultMapLocation,
    currentMapRadius: number = 10000,
  ) {
    console.log('loadParking');
    this._orderService.updateIfOrderForNow();
    if (
      this.isZoomGood &&
      this.isNewParkingsRange(currentMapCenter, currentMapRadius) ||
      !this.invokeBoundsChangedEvent
    ) {
      // fill parkings at a bigger area than current map radius:
      this.currentRadiusOfParkings = currentMapRadius * 2;
      this.currentParkingsCenter = currentMapCenter;
      this.reloadParkings(
        this.currentParkingsCenter,
        this.currentRadiusOfParkings
      )
    }
  }

  mapClick(event) {
    console.log('map click', event);
  }

  getColorByLastWashDate(parkingOnMap: ParkingOnMap): LastDateIndicator {
    switch (this.lastWash(parkingOnMap)) {
      case LastWashEnum.LESS_OR_EQUAL_TO_7_DAYS:
        // doesn't have to be washed yet
        return LastDateIndicator.Green;
      case LastWashEnum.LESS_OR_EQUAL_TO_14_DAYS:
        return LastDateIndicator.Yellow;
      case LastWashEnum.MORE_THEN_14_DAYS:
        return LastDateIndicator.Red;
      default:
        return LastDateIndicator.Gray;
    }
  }

  lastWash = (parking: Parking): LastWashEnum => {
    const diffDays = this.getLastWashDateInDays(parking);

    switch (true) {
      case diffDays < parking.washFrequencyInDays:
        return LastWashEnum.LESS_OR_EQUAL_TO_7_DAYS;
      case diffDays <= 14:
        return LastWashEnum.LESS_OR_EQUAL_TO_14_DAYS;
      case diffDays > 14:
        return LastWashEnum.MORE_THEN_14_DAYS;
      default:
        return LastWashEnum.UNKNOWN;
    }
  };

  getLastWashDateInDays(parkingOnMap: Parking): number {
    const lastWashDate = parkingOnMap?.carsDetails[0].lastWashDate;
    if (lastWashDate === null) {
      return 14;
    }
    const today = new Date(Date.now());
    const diffTime = Math.abs(
      today.getTime() - new Date(lastWashDate).getTime()
    );
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  shouldBeWashed(parkingOnMap: Parking): boolean {
    return this.getLastWashDateInDays(parkingOnMap) > parkingOnMap.washFrequencyInDays;
  }

  getColorByLastMaintenanceDate(
    parkingOnMap: ParkingOnMap
  ): LastDateIndicator {
    if (parkingOnMap.carsDetails[0].lastMaintenanceDate === null) {
      return LastDateIndicator.Red;
    }

    const _today = moment();
    const duration = moment.duration(_today.diff(parkingOnMap.carsDetails[0].lastMaintenanceDate));
    const diffAsMonths = duration.asMonths();
    switch (true) {
      case diffAsMonths <= 1:
        return LastDateIndicator.Green;
      case diffAsMonths <= 2:
        return LastDateIndicator.Yellow;
      case diffAsMonths > 2:
        return LastDateIndicator.Red;
      default:
        return LastDateIndicator.Gray;
    }
  }

  getCurrentLocationMarker() {
    return AppGlobals.POINTER_IMAGES.CURRENT_LOCATION;
  }

  getParkingPointerImgUrl(parking: Parking): string {
    const isSelectedParking = this.selectedParking && this.selectedParking.id === parking.id;

    const baseIconUrl = 'assets/images/small_icon_pointer_citycar';
    if (this.checkIfIsEmployDrive(parking)) {
      return `${baseIconUrl}_orange${this.getParkingIconByDateAndAvailability(parking as ParkingOnMap)}`;
    }

    const parkingAvailability = this.getParkingAvailability(parking);

    switch (parkingAvailability) {
      case CarAvailability.FREE:
        return isSelectedParking
          ? AppGlobals.POINTER_IMAGES.CITY_CAR_SELECTED
          : AppGlobals.POINTER_IMAGES.CITY_CAR + this.getParkingIconByDateAndAvailability(parking as ParkingOnMap);
      case CarAvailability.OVERLAPPING:
        return isSelectedParking
          ? AppGlobals.POINTER_IMAGES.CITY_CAR_ORANGE_SELECTED
          : AppGlobals.POINTER_IMAGES.CITY_CAR_ORANGE + this.getParkingIconByDateAndAvailability(parking as ParkingOnMap);
      case CarAvailability.OCCUPIED:
        return isSelectedParking
          ? AppGlobals.POINTER_IMAGES.CITY_CAR_RED_SELECTED
          : AppGlobals.POINTER_IMAGES.CITY_CAR_RED + this.getParkingIconByDateAndAvailability(parking as ParkingOnMap);
      default:
        return CarAvailability.FREE;
    }
  }

  checkIfIsEmployDrive(parking: Parking) {
    const now = new Date(Date.now());
    return parking.carsDetails?.filter(
      c =>
        c.timeSlots?.some(
          d =>
            d.used?.some(
              w =>
                w.odt &&
                new Date(w.start) < now &&
                new Date(w.end) > now
            )
        )
    ).length > 0;
  }

  getParkingIconByDateAndAvailability(parking: ParkingOnMap) {

    switch (this.getIconColor(this._userService.userMode, parking)) {
      case LastDateIndicator.Red:
        return '_red_bordered.png';
      case LastDateIndicator.Yellow:

        return '_yellow_bordered.png';
      case LastDateIndicator.Green:

        return '_green_bordered.png';

    }

    return '.png';
  }

  getIconColor = (userMode: UserMode, car: ParkingOnMap) =>
    userMode === UserMode.Wash
      ? this.getColorByLastWashDate(car)
      : this.getColorByLastMaintenanceDate(car);

  getOfficeOrderTypeByEnum() {
    if (!this.selectedParking) {
      throw new Error('selectedParking is null');
    }
    const i_1 = this.selectedParking.carsDetails.findIndex(
      c =>
        c.timeSlots != null &&
        c.timeSlots.find(
          s => s.used != null && s.used.some(t => t.odt != null)
        ) != null
    );
    const i_2 = this.selectedParking.carsDetails[i_1].timeSlots.findIndex(
      d => d.used != null && d.used.some(k => k.odt != null)
    );
    const i_3 = this.selectedParking.carsDetails[i_1].timeSlots[
      i_2
    ].used.findIndex(h => h.odt != null);
    const odtType =
      this.selectedParking.carsDetails[i_1].timeSlots[i_2].used[i_3].odt;
    switch (odtType) {
      case 0:
        return 'מוסך';
      case 1:
        return 'הרשמה';
      case 2:
        return 'תיקונים';
      case 3:
        return 'שונות';
      case 4:
        return 'פנצ\'ר';
      case 5:
        return 'שטיפה';
      case 6:
        return 'טסט';
      case 7:
        return 'תדלוק';
      default:
        return 'לא ידוע';
    }
  }

  getParkingAvailability(parking: Parking): CarAvailability {
    if (!parking.carsDetails) {
      return CarAvailability.OCCUPIED;
    }

    for (const carDetails of parking.carsDetails) {
      if (this.selectedCarCategories.includes(carDetails.carCategory)) {
        if (
          carDetails.availability === CarAvailability.FREE ||
          carDetails.availability === CarAvailability.OVERLAPPING
        ) {
          return carDetails.availability;
        }
      }
    }

    return CarAvailability.OCCUPIED;
  }

  private isNewParkingsRange(
    currentMapCenter: MapLocation,
    currentMapRadius: number
  ) {
    if (!this.currentParkingsCenter) {
      return true;
    }

    const distanceFromParkingsCenter =
      this._googleMapsService.getDistanceFromLatLonInKm(
        currentMapCenter,
        this.currentParkingsCenter
      );
    return distanceFromParkingsCenter + currentMapRadius >
      this.currentRadiusOfParkings;
  }

  closeZoomAlert() {
    this.showZoomAlert = false;
    setTimeout(() => {
      this.showZoomAlert = true;
    }, 120 * 1000);
  }

  private async reloadParkings(
    center = this.currentParkingsCenter,
    radiosKM = this.currentRadiusOfParkings,
    isShowLoading = true
  ): Promise<Parking[] | undefined> {
    try {
      (async (): Promise<any[]> => await this._parkingsService.loadLastMaintenances())();
      const parkings = await this._parkingsService.getParkingsByCenterAndRadios(center, radiosKM, isShowLoading)

      // updated the received parkings:
      parkings.forEach(parking => {
        const keepOldFrequency = (oldParking: Parking, newParking: ParkingOnMap) => ({
          ...newParking,
          washFrequencyInDays: oldParking ? oldParking.washFrequencyInDays : AppGlobals.FREQUENCIES_DEFAULT_VALUES.WASH,
          maintenanceFrequencyInDays: oldParking
            ? oldParking.maintenanceFrequencyInDays
            : AppGlobals.FREQUENCIES_DEFAULT_VALUES.MAINTENANCE
        });

        const parkingId = this.parkings.find(p => p.id === parking.id);
        if (this._userService.userMode === UserMode.Maintenance) {
          const lastMaintenanceDetails =
            this._parkingsService.lastMaintenances.find(
              l => l.stationId === parking.id
            );
          if (lastMaintenanceDetails) {
            const car = parking.carsDetails.find(
              c => c.carNumber === Number(lastMaintenanceDetails.carNumber)
            );
            if (lastMaintenanceDetails && car) {
              car.lastMaintenanceDate = lastMaintenanceDetails.lastMaintinceCheck;

            }
          }
        }
        if (parkingId) {
          const parkingIndex = this.parkings.indexOf(
            parkingId
          );
          this.parkings[parkingIndex] = keepOldFrequency(this.parkings[parkingIndex], parking);
        }
      });
      this.reInitLastMaintenanceCheck();

      this._loadingService.stopLoading();
      if (!this.showAllParkings) {
        this.reFilterParkingsByUserMode();
      }

      // refresh selected parking from new array:
      this.refreshSelectedParking();
      // force to make the binding:
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }

      return parkings;
    } catch (error) {
      if (error instanceof ErrorResponse) {
        this._alertService.error(error.CustomErrorMessage);
      }
      this._loadingService.stopLoading();
      console.error(error);
    }
  }

  reInitLastMaintenanceCheck() {
    // this._parkingsService.fillLastMaintenanceInfo();
  }

  private reFilterParkingsByUserMode() {
    const mode = this._userService.userMode;
    if (mode === UserMode.Wash) {
      this.parkings = this.parkings.filter(
        (p): boolean =>
          !p.carsDetails ||
          p.carsDetails?.some(
            (c): boolean =>
              !c.lastWashDate ||
              moment(c.lastWashDate).add(p.washFrequencyInDays, 'days') <
              moment()
          )
      );
    } else if (mode === UserMode.Maintenance) {
      this.parkings = this.parkings.filter(
        (p): boolean =>
          !p.carsDetails ||
          p.carsDetails.some(
            c =>
              !c.lastMaintenanceDate ||
              moment(c.lastMaintenanceDate).add(
                p.maintenanceFrequencyInDays,
                'days'
              ) < moment()
          )
      );
    }
  }

  private refreshSelectedParking() {
    const parking = this.parkings.find(p => p.id === this.selectedParking?.id);
    if (
      this.selectedParking && parking
    ) {
      this.selectedParking = parking as ParkingOnMap;
    }
  }

  getLocationBySearchOption(
    searchBy: SearchByLocationOptions
  ): Observable<MapLocation | null> {
    if (searchBy === SearchByLocationOptions.CurrentLocation) {
      return this._googleMapsService.getCurrentPosition();
    } else if (searchBy === SearchByLocationOptions.RegisteredAddress) {
      const locInStorage = localStorage.getItem(
        AppGlobals.LOCAL_STORAGE.Location
      );
      if (locInStorage) {
        return of(JSON.parse(locInStorage));
      } else {
        const user = this._userService.user;
        const address = `${user.street} ${user.streetNumber} ${user.city}`;
        return this._googleMapsService
          .geocodeAddress(address)
          .pipe(
            map((location: MapLocation) => {
              localStorage.setItem(
                AppGlobals.LOCAL_STORAGE.Location,
                JSON.stringify(location)
              );
              return location;
            })
          );
      }
    } else {
      return of(null);
    }
  }

  private setDefoultLocation(
    searchBy: SearchByLocationOptions
  ): Observable<MapLocation | null> {
    return this.getLocationBySearchOption(searchBy)
      .pipe(
        map((location: MapLocation | null) => {
          if (location) {
            this.mapOptions.location = location;
            this.currentLocation = location;
            this.mapOptions.zoom = this.defoultMapZoom;
            console.log('finish get current location', this.currentLocation);

            this.setMapCenter(location);

            return location;
          } else {
            location = this.defaultMapLocation;
            this.setMapCenter(location, false);
            return location;
          }
        }),
        catchError(() => {
          const location = this.defaultMapLocation;
          this.setMapCenter(location, false);
          return of(location || null);
        })
      );
  }

  private setMapCenter(
    location: MapLocation,
    isSetAddressName = true
  ) {
    this.mapOptions.location = location;
    this.currentLocation = location;
    this.mapOptions.zoom = this.defoultMapZoom;

    if (isSetAddressName) {
      this._googleMapsService
        .getAddressName(location)
        .then((addressName: string) => {
          console.log('set address name', addressName);
          this.currentAddressName = addressName;

          // force to make the binding:
          if (!this.cdRef['destroyed']) {
            this.cdRef.detectChanges();
          }
        });
    } else {
      this.currentAddressName = '';
    }
  }

  private custToMapLocation(eventLocation) {
    return <MapLocation>{
      lat: eventLocation.lat(),
      lon: eventLocation.lng(),
    };
  }

  navigateToCar() {
    if (!this.selectedParking) {
      throw new Error('No selected parking');
    }
    this._globalService.navigateToCar(
      this.selectedParking.earthLocation.lat,
      this.selectedParking.earthLocation.lon
    );
  }

  reporMaintenance() {
    console.log('reporMaintenance');
    this._modalService.openComponentModal(
      new ModalContext<any>(this.selectedCar, () => { }),
      ReportMaintenanceComponent
    );
  }
}

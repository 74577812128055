import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import PageTitle from '../../models/page-title';
import { City } from '../../models/city';
import { Order } from '../../models/order';
import { Parking } from '../../models/parking';
import { UsersPreference } from '../../models/user';
import { OrderService } from '../../services/order.service';
import { ParkingsService } from '../../services/parkings.service';
import { UserMode, UserService } from '../../services/user.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationComponent implements OnInit {
  UserMode: typeof UserMode = UserMode;

  get pageTitle(): PageTitle {
    return {
      title: 'ORDER_BY_STATION',
      previousUrl: '/menu',
    };
  }

  cities: City[];
  parkings: Parking[];
  neighborhoods: string[];
  selectedCity = 0;
  selectedNeighborhood: string;
  selectedNeighborhoods: string[] = new Array<string>();
  preferencesParkings: UsersPreference;
  showPreferences = false;
  showAllParkings = false;

  constructor(
    private _parkingsService: ParkingsService,
    private _orderService: OrderService,
    private _router: Router,
    public _userService: UserService,
    private dataService: DataService,
  ) { }

  async ngOnInit() {
    this.cities = this.showAllParkings ? this.dataService.cities : this.dataService.permittedCities;
  }

  getStationsByCity(cityId: number) {
    this.showPreferences = false;
    this.parkings = [];
    this.selectedNeighborhoods = [];
    this.parkings = this.dataService.workNeededParkings.filter((p) => p.cityId === cityId);
    debugger
    this.neighborhoods = Array.from(new Set(this.dataService.workNeededParkings.filter((p) => p.cityId === cityId).map((p) => p.neighborhood)));
    const index = this.neighborhoods.findIndex((n) => n === null);
    if (index > -1) {
      this.neighborhoods.splice(index, 1);
    }
    this.selectedCity = cityId;
  }

  async filterStationByNeighborhood(cityId: number, neighborhood: string) {
    const parkings = await this._parkingsService.filterParkingsByCityId(this.dataService.workNeededParkings, cityId);
    this.parkings = parkings.filter((p) => p.neighborhood === neighborhood);
  }

  selectParking(parkingIndex: number) {
    const order = new Order();
    this._orderService.StartParking = this.parkings[parkingIndex];
    order.startTime = moment().add(2, 'minutes').set('s', 0).toDate();
    order.endTime = moment(order.startTime).add(20, 'minutes').toDate();
    order.period = 1;
    order.startParking = this.parkings[parkingIndex];
    this._orderService.changeCurrentOrder(order);

    this._router.navigate(['/order/stationsInMap']);
  }

  onChange($event) {
    this._parkingsService
      .filterParkingsByCityId(this.dataService.workNeededParkings, this.selectedCity)
      .then((parkings: Parking[]) => {
        if (this.selectedNeighborhoods.length > 0) {
          this.parkings = parkings.filter((p) =>
            this.selectedNeighborhoods.includes(p.neighborhood)
          );
        } else {
          this.parkings = parkings;
        }
      });
  }

  async getPreferenceParkings(): Promise<void> {
    this.showPreferences = !this.showPreferences;
    this.selectedCity = null;
    this.parkings = [];
    this.parkings = (await this.dataService.getParkings()).filter((p) =>
      this.preferencesParkings.parkings.includes(p.id)
    );
    console.log(this.parkings);
  }

  async toggleShowAllParkings() {
    this.showAllParkings = !this.showAllParkings;
    if (this.showAllParkings) {
      this.parkings = await this.dataService.getParkings();
    } else {
      this.parkings = this.dataService.workNeededParkings;
    }
    this.cities = this.showAllParkings ? this._parkingsService.cities : this._parkingsService.permittedCities;
  }

  get showAllParkingsButton(): string {
    return this.showAllParkings ? 'סנן תחנות' : 'טען כל התחנות';
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs/observable/from';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from './url.service';
import { DamageReport } from '../models/damage-report';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { HttpService } from './http.service';
import { forkJoin } from 'rxjs';

@Injectable()
export class MessagesService {
  constructor(
    private translate: TranslateService,
    private _httpService: HttpService,
    private _urlService: UrlService
  ) { }

  damageReport(orderId: number, damageReport: DamageReport): Observable<any> {
    const body = {
      orderId: orderId,
      updateSource: 3,
      notes: damageReport.note,
      picture: damageReport.image,
    };
    return from(
      this._httpService.post(
        this._urlService.baseUrl() + '/orders/DamageAlert?pretty=1',
        body
      )
    );
  }

  damageReports(
    orderId: number,
    damageReports: DamageReport[]
  ): Observable<any> {
    const damagesObs: Observable<any>[] = [];
    damageReports.forEach((dr) => {
      damagesObs.push(this.damageReport(orderId, dr));
    });
    return forkJoin(damagesObs);
  }

  loginMessages(subscriptionStatus: number): { TITLE: string; MESSAGE: string; BTN1: string; } | undefined {

    switch (subscriptionStatus) {
      case 1:
        return this.translate.currentLang == 'he'
          ? AppGlobals.MESSAGES.CANCELED.HE
          : AppGlobals.MESSAGES.CANCELED.EN;
      case 2:
        return this.translate.currentLang == 'he'
          ? AppGlobals.MESSAGES.BLOCKED.HE
          : AppGlobals.MESSAGES.BLOCKED.EN;
      case 3:
        return this.translate.currentLang == 'he'
          ? AppGlobals.MESSAGES.PENDING.HE
          : AppGlobals.MESSAGES.PENDING.EN;
      case 4:
        return this.translate.currentLang == 'he'
          ? AppGlobals.MESSAGES.UPDATE.HE
          : AppGlobals.MESSAGES.UPDATE.EN;
    }

  }
}

<!-- date editor: -->
<div>
  <div class="row" *ngIf="!_orderService.isOrderForNow">
    <app-order-date-editor
      (changeTime)="emitChangeTime()"
    ></app-order-date-editor>
  </div>
  <div class="editDetails">
    <button
      class="dddd"
      id="editDateBtn"
      (click)="isShowEditTime = true"
      translate
    >
      CHANGE_TIMES
    </button>
    <button
      *ngIf="isShowCarCategorySortButton"
      class=""
      id="sortByCarTypeBtn"
      (click)="isShowCarCategorySort = true"
    >
      סנן לפי סוג רכב
    </button>
    <button id="loadParking" (click)="loadParking()">רענן מפה</button>
  </div>
</div>

<agm-map
  *ngIf="mapOptions && mapOptions.location"
  [latitude]="mapOptions.location.lat"
  [longitude]="mapOptions.location.lon"
  (zoomChange)="zoomChange($event)"
  [zoom]="mapOptions.zoom"
  (boundsChange)="boundsChange($event)"
  (mapClick)="mapClick($event)"
  [gestureHandling]="mapOptions.gestureHandling"
>
  <div *ngIf="parkings">
    <div *ngFor="let parking of parkings; let i = index">
      <agm-marker
        *ngIf="
          parking.carsDetails === null ||
          (parking.carsDetails.length > 0 &&
            isCarCategorySelected(parking) &&
            shouldBeWashed(parking))
        "
        style="z-index: 1000"
        [latitude]="parking.earthLocation.lat"
        [longitude]="parking.earthLocation.lon"
        [iconUrl]="getParkingPointerImgUrl(parking)"
        (markerClick)="selectParkingByIndex(i)"
      >
      </agm-marker>
    </div>
  </div>
  <agm-marker
    *ngIf="!this._orderService.Order?.startParking"
    [latitude]="currentLocation.lat"
    [longitude]="currentLocation.lon"
    [iconUrl]="getCurrentLocationMarker()"
  >
  </agm-marker>
</agm-map>

<div class="all-parkings-button" style="border: tomato 2px solid">
  <button
    *ngIf="isInMaintenanceMode && !selectedParking"
    type="button"
    class="btn btn-primary"
    data-toggle="button"
    id="showAllParkings"
    (click)="toggleShowAllParkings()"
  >
    {{ showAllParkings ? 'סנן תחנות' : 'כל התחנות' }}
  </button>
</div>

<!-- footer :search and car details -->
<div>
  <ng-container
    *ngIf="selectedParking; then parkingDetails; else searchPlace"
  ></ng-container>

  <ng-template #parkingDetails>
    <div class="parkingDetails">
      <div *ngIf="!selectedCar">
        <div class="detail-must">
          <a (click)="selectedParking = null">
            <img
              src="assets/images/close_button.png"
              class="close-btn1"
              alt=""
            />
          </a>
          <h3 class="h3-title">{{ selectedParking.name }}</h3>
          <div class="alert alert-danger" translate>NO_CARS</div>
        </div>
      </div>

      <!-- <div *ngIf="!selectedParking.isWithDetails">
        <div class="detail-must">
          <a (click)="selectedParking = null">
            <img
              src="assets/images/close_button.png"
              class="close-btn1"
              alt=""
            />
          </a>
          <h3 class="h3-title">{{ selectedParking.name }}</h3>
          <div class="alert alert-danger" translate>LOAD</div>
        </div>
      </div> -->

      <div *ngIf="selectedCar">
        <ng-template
          [ngIf]="selectedCar.availability === CarAvailability.FREE"
          #carDetails
        >
          <app-car-details
            [car]="selectedCar"
            [addressName]="selectedParking.name"
            [parking]="selectedParking"
            [userMode]="_userService.userMode"
            (sendDataToParent)="eventFromChild($event)"
          >
          </app-car-details>

          <div class="col-xs-12 continue-container">
            <button
              *ngIf="isInMaintenanceMode"
              style="font-size: 14px; padding: 2px; margin: 5px"
              (click)="reporMaintenance()"
            >
              דוח תחזוקה
            </button>
            <button
              *ngIf="isInMaintenanceMode"
              style="font-size: 14px; padding: 2px; margin: 5px"
              (click)="openOfficeDrive()"
            >
              להזמנה
            </button>
            <button
              reporMaintenance
              style="font-size: 14px; padding: 2px; margin: 5px"
              (click)="WashCarNow()"
            >
              {{ addOrderNowButtonString }} עכשיו
            </button>
            <button
              id="editDateBtn"
              style="font-size: 14px; padding: 2px; margin: 5px"
              (click)="WashCarLater()"
            >
              {{ addOrderNowButtonString }} אח"כ
            </button>
          </div>
        </ng-template>

        <ng-template
          [ngIf]="selectedCar.availability === CarAvailability.OVERLAPPING"
          #carNotCompletelyAvailable
        >
          <div class="detail-must">
            <a (click)="selectedParking = null">
              <img src="assets/images/close_button.png" class="close-btn1" />
            </a>
            <div
              *ngIf="selectedParking.carsDetails?.length > 0"
              class="col-xs-12"
            >
              <div *ngFor="let carDetail of selectedParking.carsDetails">
                <span *ngIf="_userService.userMode === UserMode.Wash">
                  <b>רכב {{ carDetail.carNumber }}:</b> שטיפה אחרונה ב-{{
                    carDetail.lastWashDate | date: 'dd/MM/yyyy HH:mm'
                  }}
                </span>
                <span *ngIf="_userService.userMode === UserMode.Maintenance">
                  <b>רכב {{ carDetail.carNumber }}:</b> תחזוקה אחרונה ב-{{
                    carDetail.lastMaintenanceDate | date: 'dd/MM/yyyy HH:mm'
                  }}
                </span>
              </div>
            </div>
            <div *ngIf="checkIfIsEmployDrive(selectedParking)">
              <span>
                <b>סוג נסיעת עובדים:</b> {{ getOfficeOrderTypeByEnum() }}
              </span>
            </div>
            <h3 class="h3-title">{{ selectedParking.name }}</h3>
            <h4>{{ selectedCar.carType }}</h4>
            <div class="alert alert-danger">
              <b translate> AVAILABLE </b>
              <ul style="font-size: 12px">
                <li *ngFor="let timeSlot of selectedCarAvailability">
                  {{ timeSlot }}
                </li>
              </ul>
              <button
                id="editDateBtn"
                class="change-btn"
                (click)="isShowEditTime = true"
                translate
              >
                CHANGE_TIMES
              </button>
              <div style="clear: both"></div>
            </div>

            <div
              style="
                display: table;
                margin: 0 auto;
                margin-bottom: 15px;
                margin-top: 15px;
              "
            >
              <div class="waze-navigator" (click)="navigateToCar()">
                נווט באמצעות
                <img
                  alt="waze"
                  src="https://img.icons8.com/color/22/000000/waze.png"
                />
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template
          [ngIf]="selectedCar.availability === CarAvailability.OCCUPIED"
          #carNotAvailable
        >
          <div class="detail-must">
            <a (click)="selectedParking = null">
              <img
                src="assets/images/close_button.png"
                class="close-btn1"
                alt=""
              />
            </a>
            <div
              *ngIf="
                selectedParking.carsDetails !== null &&
                selectedParking.carsDetails !== undefined &&
                selectedParking.carsDetails.length > 0
              "
              class="col-xs-12"
            >
              <div *ngFor="let carDetail of selectedParking.carsDetails">
                <span *ngIf="_userService.userMode === UserMode.Wash">
                  <b>רכב {{ carDetail.carNumber }}:</b> שטיפה אחרונה ב-{{
                    carDetail.lastWashDate | date: 'dd/MM/yyyy HH:mm'
                  }}
                </span>
                <span *ngIf="_userService.userMode === UserMode.Maintenance">
                  <b>רכב {{ carDetail.carNumber }}:</b> תחזוקה אחרונה ב-{{
                    carDetail.lastMaintenanceDate | date: 'dd/MM/yyyy HH:mm'
                  }}
                </span>
              </div>
            </div>
            <div *ngIf="checkIfIsEmployDrive(selectedParking)">
              <span>
                <b>סוג נסיעת עובדים:</b> {{ getOfficeOrderTypeByEnum() }}
              </span>
            </div>
            <h3 class="h3-title">{{ selectedParking.name }}</h3>
            <h4>{{ selectedCar.carType }}</h4>
            <div class="alert alert-danger">
              <b translate> AVAILABLE_MESSAGE</b>

              <!-- <b> נסה לשנות את שעות ההזמנה </b> -->
              <ul>
                <li *ngFor="let timeSlot of selectedCarAvailability">
                  {{ timeSlot }}
                </li>
              </ul>
              <button
                id="littleChangeTime"
                (click)="isShowEditTime = true"
                class="change-btn"
                translate
              >
                CHANGE_TIMES
              </button>
              <div style="clear: both"></div>
            </div>

            <div
              style="
                display: table;
                margin: 0 auto;
                margin-bottom: 15px;
                margin-top: 15px;
              "
            >
              <div class="waze-navigator" (click)="navigateToCar()">
                נווט באמצעות
                <img
                  alt="waze"
                  src="https://img.icons8.com/color/22/000000/waze.png"
                />
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div
      class="searchPlace-bottom"
      style="bottom: 42px; position: fixed; width: 90%"
    >
      <app-place-autocomplete
        (selectAddressEvent)="selectAddress($event)"
      ></app-place-autocomplete>
    </div>
  </ng-template>

  <ng-template #searchPlace>
    <div class="searchPlace">
      <app-place-autocomplete
        (selectAddressEvent)="selectAddress($event)"
      ></app-place-autocomplete>
    </div>
  </ng-template>
</div>
<div
  class="carCategoryDiv"
  *ngIf="isShowCarCategorySort"
  (click)="stopProposition($event)"
>
  <h4 class="title-popup" translate>CHECK_TYPES</h4>
  <ul>
    <li>
      <input type="checkbox" [(ngModel)]="isSelectAllCategories" />
      <label>הכל</label>
    </li>
    <li *ngFor="let carCategory of carCategories">
      <input
        type="checkbox"
        [value]="carCategory.id"
        [(ngModel)]="carCategory.isSelected"
      />
      <label> {{ carCategory.name }}</label>
    </li>
  </ul>
  <button (click)="sortBycarCategory()" id="closeCategoryBtn" translate>
    CLOSE
  </button>
</div>

<div
  class="carCategoryDiv"
  *ngIf="isShowEditTime"
  (click)="stopProposition($event)"
>
  <button
    class="close-btn"
    id="modal-close-btn"
    (click)="isShowEditTime = false"
  >
    <i class="fas fa-times"></i>
  </button>
  <app-select-time [isShowSmallFormat]="true" (changeTime)="emitChangeTime()">
  </app-select-time>
</div>

import { Injectable } from '@angular/core';
import { ModalService } from './modal.service';
import { Observable, Subject } from 'rxjs';
import { OrderService } from './order.service';
import * as moment from 'moment';
import { Order } from '../models/order';
import { Parking } from '../models/parking';
import { AppGlobals } from '../shared/app-globals/app-globals';

@Injectable()
export class IrregularOrderService {

  isWantIrregularOrder: boolean;
  dataSubject: Subject<any> = new Subject<any>();

  constructor(
    private _modalService: ModalService,
    private _orderService: OrderService
  ) {}

  isTenMinuteBefore(thisOrderEndTime: Date, nextOrderStartTime: Date) {
    return (nextOrderStartTime.getTime() - thisOrderEndTime.getTime()) /
        AppGlobals.TIMES.MINUTES_IN_TIME >
        10;
  }

  getIsWantIrregularOrder() {
    return this.dataSubject.asObservable();
  }
  setIsWantIrregularOrder(isWantIrregularOrder) {
    this.isWantIrregularOrder = isWantIrregularOrder;
    this.dataSubject.next(this.isWantIrregularOrder);
  }

  wantIrregularOrder(
    previosOrder: Order,
    selectedParking: Parking
  ): Observable<number> {
    const preOrderEndTime = moment(previosOrder.endTime).toDate();
    const preOrderStartTime = moment(previosOrder.startTime).toDate();
    preOrderEndTime.setSeconds(0);
    preOrderStartTime.setSeconds(0);
    console.log(preOrderEndTime, preOrderStartTime);
    const currentCar = selectedParking.carsDetails[0].timeSlots[0].used.find(
      (c) =>
        preOrderEndTime > new Date(c.start) &&
        preOrderStartTime < new Date(c.start)
    );
    console.log(currentCar);

    if (currentCar != undefined) {
      const isTenMinuteBefore = this.isTenMinuteBefore(
        preOrderStartTime,
        moment(currentCar.start).toDate()
      );

      const calcTimeOfRent =
        moment(currentCar.start).toDate().getTime() -
        preOrderStartTime.getTime();
      const realHoursOfRent = Math.floor(
        calcTimeOfRent / AppGlobals.TIMES.HOURS_IN_TIME
      );
      const realMinutesOfRent =
        Math.floor(calcTimeOfRent) / AppGlobals.TIMES.MINUTES_IN_TIME -
        realHoursOfRent * 60;
      const realDaysOfRent = Math.ceil(
        Math.floor(calcTimeOfRent) / AppGlobals.TIMES.MINUTES_IN_TIME / 60 / 24
      );
      const hoursToAdd = Math.ceil(
        calcTimeOfRent / AppGlobals.TIMES.HOURS_IN_TIME
      );
      const hoursOrDays =
        this._orderService.Order.period == 3 ? realDaysOfRent : hoursToAdd;
      const hoursOrDaysString =
        this._orderService.Order.period == 3 ? ' ימים מלאים ' : ' שעות מלאות ';

      const startTimeForMassage = moment(currentCar.start).format(
        'HH:mm DD/MM/YY'
      );

      const msg =
        'בחרת בהזמנת ' +
        this._orderService.Order.periodName +
        '<br>אך רכב זה צריך לשוב אל התחנה עד <a>' +
        startTimeForMassage +
        '</a> <h4> באפשרותך להזמין את הרכב <br>' +
        'ל: <a>' +
        realHoursOfRent +
        ' שעות ו - ' +
        realMinutesOfRent +
        ' דקות </a> <br>' +
        '<h3> במחיר <a>' +
        hoursOrDays +
        ' </a> ' +
        hoursOrDaysString +
        ' </h3>' +
        '<br></h4> <h5> או להקדים את שעת ההתחלה </h5>';

      if (isTenMinuteBefore) {
        this._modalService.confirm2btn(
          'שים לב',
          msg,
          'אישור',
          'ביטול',
          () => {
            this._orderService.Order.endTime = moment(
              currentCar.start
            ).toDate();
            console.log(
              this._orderService.Order.endTime,
              this._orderService.Order.startTime
            );
            this._orderService.Order.isIrregularOrder = true;
            this.setIsWantIrregularOrder(true);
          },
          () => {
            this._orderService.Order.isIrregularOrder = false;
            this.setIsWantIrregularOrder(false);
          }
        );
      }

      return this.getIsWantIrregularOrder();
    }
  }
}

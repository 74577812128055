import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import PageTitle from '../../models/page-title';
import { UserMode, UserService } from '../../services/user.service';

@Component({
  selector: 'app-mode-menu',
  templateUrl: './mode-menu.component.html',
  styleUrls: ['./mode-menu.component.css'],
})
export class ModeMenuComponent implements OnInit {
  UserMode: typeof UserMode = UserMode;
  get pageTitle() {
    return <PageTitle>{
      title: 'MENU',
      previousUrl: '/home',
    };
  }

  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() {
    console.log('i am in mode menu');
  }

  async SetUserMode(userMode: UserMode) {
    this.userService.userMode = userMode;
    this.router.navigate(['/menu']);
  }
}

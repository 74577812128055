/**
 * Global constants. You can define your constant and access it by importing this file in the component.
 */
import { environment } from '../../../environments/environment';

const baseImagesUrl = 'assets/images/';
const baseCarsImagesUrl = 'assets/images/cars/';
const baseCarsLogoUrl = 'assets/images/cars/model/';

export const AppGlobals = {
  BASE_URL: environment.BASE_URL,
  TEST_URL: environment.TEST_URL,
  GUEST_URL: environment.GUEST_URL,
  NODE_SERVER: environment.NODE_SERVER,
  LOCAL_NODE: environment.LOCAL_NODE,

  IS_AGENTS: false,
  AGENTS_USER_ID: null,
  URL_ROUTES: {
    SUBSCRIPTION: 'subscriptions?pretty=1',
  },
  ID: 3,
  APP_VERSION: null,
  DEVICE: null,
  LOGIN_BTN: null,
  IMAGES_DATA: {
    MAX_SIZE_KB: {
      REGISTER: 2000,
      DAMAGE_REPORT: 4000,
    },
  },
  LANGUAGES: {
    EN: 'en',
    HE: 'he',
  },
  TIME_ZONE: {
    JERUSALEM: 'Asia/Jerusalem',
  },
  APP: {
    CHECK_VERSION_URL: 'http://145.239.255.210:3000',
    GOOGLE_PLAY_HREF: 'market://details?id=com.citycar.office',
  },
  DOCS: {
    TERMS_PDF: 'assets/docs/terms.jpg',
    REGULATIONS_PDF: 'assets/docs/regulations.jpg',
  },
  HOURS_AFTER_RENT_FOR_DAMAGE_REPORT: 2,
  LOCAL_STORAGE: {
    AccessTokenType: 'token_type',
    AccessToken: 'access_token',
    AuthState: 'AuthState',
    AccessTokenObject: 'access_token_object',
    Agents: 'uas55',
    Location: 'loc',
    IS_DEV: 'isDev',
    ON_RESUME: 'resume',
    REGISTER_USER: 'regUsr',
  },
  TIMES: {
    MINUTES_IN_TIME: 60000,
    HOURS_IN_TIME: 60 * 60 * 1000,
  },
  DEFAULT_OFFICE_DRIVE_TIME: 1200000,
  FREQUENCIES_DEFAULT_VALUES: {
    WASH: 7,
    MAINTENANCE: 30,
  },
  MINUTES_TO_RELOAD_PARKINGS: 5,
  MINUTES_FOR_DAMAGE_REPORT: 10,
  GOOGLE_CLIENT_ID:
    '466193368297-v882ms0g2kq750hu0tsf9lld6vted6cj.apps.googleusercontent.com',
  ERRORS_MASSAGES: {
    NO_CARS_IN_PARKING: 'אין רכבים זמינים בתחנה זו',
    AS_SUB: {
      GET: 'cannot enter as sub',
      SET: 'לא ניתן להתחבר למנוי שהוא נציג סיטי קאר',
    },
    FIND_USER: {
      GET: 'cant find user 55.. #@ ',
      SET: 'חשבון GMAIL אינו קיים במערכת',
    },
    FIND_SUBS: {
      GET: 'cant find subs 55.. #@ ',
      SET: 'מספר הלקוח אינו מזוהה במערכת',
    },
    PERMISSIONS: {
      GET: 'user without permissions',
      SET: 'חסרות לך הרשאות לכניסה למערכת',
    },
  },
  PERIOD_NAMES: {
    1: 'שעתי',
    3: 'יומי',
    4: 'אקסטרה 7',
  },
  PREVIOUS_URL: '',
  PERIOD_IMAGES: {
    HOURLY_SELECTED_IMG_URL: baseImagesUrl + 'houre.png',
    HOURLY_NOT_SELECTED_IMG_URL: baseImagesUrl + 'houre1.png',
    DAYLY_SELECTED_IMG_URL: baseImagesUrl + 'sun1.png',
    DAYLY_NOT_SELECTED_IMG_URL: baseImagesUrl + 'sun.png',
    SEVEN_HOURS_SELECTED_IMG_URL: baseImagesUrl + '7hours-1.png',
    SEVEN_HOURS_NOT_SELECTED_IMG_URL: baseImagesUrl + '7hours.png',
  },

  CARS_IMAGES: {
    'ניסן מיקרה': {
      IMG: baseCarsImagesUrl + 'Nissan-Micra.png',
      LOGO: baseCarsLogoUrl + 'Nissan.png',
    },
    'מרצדס בנץ  סמארט': {
      IMG: baseCarsImagesUrl + 'Mercedes-benz-Smart.png',
      LOGO: baseCarsLogoUrl + 'Mercedes.png',
    },
    'קיה פיקנטו': {
      IMG: baseCarsImagesUrl + 'Kia-Picanto.png',
      LOGO: baseCarsLogoUrl + 'Kia.png',
    },
    'מיצובישי ספייס סטאר': {
      IMG: baseCarsImagesUrl + 'Mitsubishi-Space-St.png',
      LOGO: baseCarsLogoUrl + 'Mitsubishi.png',
    },
    '   מיצובישי אטראז': {
      IMG: baseCarsImagesUrl + 'Mitsubishi-Attrage.png',
      LOGO: baseCarsLogoUrl + 'Mitsubishi.png',
    },
    'פיאט 500': {
      IMG: baseCarsImagesUrl + 'Fiat500.png',
      LOGO: baseCarsLogoUrl + 'Fiat.png',
    },
    'מרצדס בנץ  סי אל איי': {
      IMG: baseCarsImagesUrl + 'Mercedes-benz-Smart.png',
      LOGO: baseCarsLogoUrl + 'Mercedes.png',
    },
    'סיטרואן  סי 1': {
      IMG: baseCarsImagesUrl + 'citroen-c1.png',
      LOGO: baseCarsLogoUrl + 'citroen.png',
    },
    'פיגו  301': {
      IMG: baseCarsImagesUrl + 'peugeot301.png',
      LOGO: baseCarsLogoUrl + 'Peugeot.png',
    },
    'סיטרואן  סי 4 פיקאסו': {
      IMG: baseCarsImagesUrl + 'citroen-c4.png',
      LOGO: baseCarsLogoUrl + 'citroen.png',
    },
    'ניסן גוק': {
      IMG: baseCarsImagesUrl + 'Nissan-Micra.png',
      LOGO: baseCarsLogoUrl + 'Nissan.png',
    },
    'מיצובישי אטראז': {
      IMG: baseCarsImagesUrl + 'Mitsubishi-Attrage.png',
      LOGO: baseCarsLogoUrl + 'Mitsubishi.png',
    },
    NO_DATA: {
      IMG: baseImagesUrl + 'carslide2.png',
      LOGO: baseCarsLogoUrl + 'nodata.png',
    },
  },
  POINTER_IMAGES: {
    CURRENT_LOCATION: baseImagesUrl + 'current_location_small.png',
    MAN_WALKING: baseImagesUrl + 'pedestrian-walking.png',

    CITY_CAR: baseImagesUrl + 'icon_pointer_citycar',
    CITY_CAR_SELECTED: baseImagesUrl + 'icon_pointer_citycar_selected.png',

    CITY_CAR_RED: baseImagesUrl + 'icon_pointer_citycar_grey',
    CITY_CAR_RED_SELECTED:
      baseImagesUrl + 'icon_pointer_citycar_grey_selected.png',

    CITY_CAR_ORANGE: baseImagesUrl + 'icon_pointer_citycar_blue',
    CITY_CAR_ORANGE_SELECTED:
      baseImagesUrl + 'icon_pointer_citycar_blue_selected.png',

    CITY_CAR_WIHTOUT_DETAILS:
      baseImagesUrl + 'small_icon_pointer_citycar_2.png',
  },
  CONTACT_DETAILS: {
    PHONE: '*2319',
    MAIL: 'citycar@citycar.co.il',
    SUPPORT: 'support@citycar.co.il',
  },
  MESSAGES: {
    DOCUMENTS_UPDATE: 'עדכון מסמכים | ',

    CANCELED: {
      HE: {
        TITLE: 'המנוי מבוטל',
        MESSAGE: 'לצורך חידוש המנוי אנא היכנס למסך ההרשמה ומלא את הפרטים מחדש',
        BTN1: 'אישור',
      },
      EN: {
        TITLE: 'Subscription canceled',
        MESSAGE:
          'To renew the subscription please enter the registration screen and fill in the details again',
        BTN1: 'OK',
      },
    },
    BLOCKED: {
      HE: {
        TITLE: 'המנוי חסום',
        MESSAGE: 'אנא פנה למקד שירות הלקוחות לקבלת פרטים',
        BTN1: 'אישור',
      },
      EN: {
        TITLE: 'Subscribtion is blocked',
        MESSAGE: 'Please contact customer service target for details',
        BTN1: 'OK',
      },
    },
    PENDING: {
      HE: {
        TITLE: 'מנוי ממתין לאישור',
        MESSAGE:
          'אנא המתן בסבלנות לאישור המנוי על ידי נציג. הודעה תשלח לכתובת המייל המעודכנת במערכת',
        BTN1: 'אישור',
      },
      EN: {
        TITLE: 'Subscription pending approval',
        MESSAGE:
          "Please be patient to confirm your subscription by a representative. A message will be sent to the system's updated email address",
        BTN1: 'OK',
      },
    },
    UPDATE: {
      HE: {
        TITLE: 'הנך נדרש להשלמת מסמכים',
        MESSAGE: 'לצורך עדכון פרטי המנוי אנא השלם את המסמכים החסרים',
        BTN1: 'להשלמת מסמכים',
      },
      EN: {
        TITLE: 'You are required to complete documents',
        MESSAGE:
          'To update the subscription details please complete the missing documents',
        BTN1: 'To complete documents',
      },
    },
  },
  STYLES: {
    IMAGE_UPLOAD: {
      selectButton: {
        'background-color': '#652f8e',
        'font-size': '15px',
        color: '#FFF',
        'font-weight': 'bold',
      },
      clearButton: {
        'background-color': '#FFF',
        color: '#320f55',
        'margin-left': '10px',
        'font-weight': 'bold',
        'font-size': '15px',
      },
      layout: {
        'background-color': '#f2f0f1',
        color: '#652f8e',
        'font-size': '15px',
        margin: '5% 0px 5% 0px',
        'font-family': "'AtlasProAAARegular'",
        'text-align': 'right',
      },
      previewPanel: {
        'background-color': 'white',
        display: 'none',
        'max-height': '105px',
        'overflow-y': 'scroll',
      },
    },
  },
};

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Parking, ParkingOnMap } from '../models/parking';
import { DatePipe } from '@angular/common';
import { GetTimeSlotsBody, filterParkingBody } from '../models/request-models';
import { GlobalService } from './global.service';
import { from } from 'rxjs/observable/from';
import { UrlService } from './url.service';
import { timeSlot, CarInParking } from '../models/car';
import { City } from '../models/city';
import { MapLocation } from '../models/map-location';
import { Order } from '../models/order';
import { CarAvailability, TimeSlotsPipe } from '../pipes/time-slots.pipe';
import { DatesService } from './dates.service';
import { GoogleMapsService } from './google-maps.service';
import { HttpService } from './http.service';
import { LoadingService } from './loading.service';
import { UserMode, UserService } from './user.service';
import { DataService } from './data.service';

@Injectable()
export class ParkingsService {

  baseParkingsUrl = 'parkings/';

  washPermittedParkings: ParkingOnMap[] = [];
  maintainPermittedParkings: ParkingOnMap[] = [];
  private _checkListItems: Promise<any[]>;
  maintainPermittedCities: City[];
  washPermittedCities: City[];
  cities: City[];
  public get permittedCities(): City[] {
    return this._userService.userMode === UserMode.Wash ? this.washPermittedCities : this.maintainPermittedCities;
  }
  // public get permittedParkings(): ParkingOnMap[] {
  //   return this._userService.userMode === UserMode.Wash ? this.washPermittedParkings : this.maintainPermittedParkings;
  // }
  public get checkListItems(): Promise<any[]> {
    return this._checkListItems ? Promise.resolve(this._checkListItems) : this.loadMaintenanceCheckListItems();
  }

  public set checkListItems(value: Promise<any[]>) {
    this._checkListItems = value;
  }

  getOrder: () => Order;

  parkingsToLoadFirst: number[];
  lastMaintenances: any[];

  private async loadMaintenanceCheckListItems() {
    const MaintenanceCheckList = await this._httpService.get<any>(
      this._urlService.nodeUrl() + 'cars/maintenance/init'
    );
    console.log(MaintenanceCheckList);
    return this.checkListItems = MaintenanceCheckList.CheckListItems;
  }

  // filterByWashFrequencyDate(parkings: ParkingOnMap[]): ParkingOnMap[] {
  //   //TODO: filter by wash frequency
  //   return parkings.filter(
  //     (p) =>
  //       !p.carsDetails ||
  //       p.carsDetails.some(
  //         (c) =>
  //           !c.lastWashDate ||
  //           moment(c.lastWashDate).add(p.washFrequencyInDays, 'days') < moment()
  //       )
  //   );
  // }

  // filterByMaintenanceFrequncyDate(parkings: ParkingOnMap[]): ParkingOnMap[] {
  //   this.fillLastMaintenanceInfo();
  //   return parkings.filter(
  //     (p) =>
  //       !p.carsDetails ||
  //       p.carsDetails.some(
  //         (c) =>
  //           !c.lastMaintenanceDate ||
  //           moment(c.lastMaintenanceDate).add(p.maintenanceFrequncyInDays, 'days') < moment()
  //       )
  //   );
  // }

  // public async fillLastMaintenanceInfo() {
  //   if (!this.lastMaintenances) {
  //     await this.loadLastMaintenances();
  //   }

  //   this.lastMaintenances.forEach((car) => {
  //     const parking = this.parkings.find((p) => p.id === car.stationId);
  //     if (parking) {
  //       const carDetails = parking.carsDetails.find(
  //         (c) => c.carNumber === Number(car.carNumber)
  //       );
  //       if (carDetails) {
  //         carDetails.lastMaintenanceDate = car.lastMaintinceCheck;
  //       }
  //     }
  //   });
  //   console.log(
  //     this.parkings.filter((p) =>
  //       p.carsDetails.some((c) => c.lastMaintenanceDate != null)
  //     )
  //   );
  // }

  constructor(
    private _httpService: HttpService,
    private _datepipe: DatePipe,
    private _globalService: GlobalService,
    private _loadingService: LoadingService,
    private _datesService: DatesService,
    private _timeSlotsPipe: TimeSlotsPipe,
    private _googleMapsService: GoogleMapsService,
    private _urlService: UrlService,
    private _userService: UserService,
    private dataService: DataService,
  ) { }

  registerFromOrderService(getOrder: () => Order) {
    this.getOrder = getOrder;
  }

  getParkingsByCenterAndRadios = async (
    center: MapLocation,
    radiusKM: number,
    isShowLoading: boolean = true,
  ): Promise<ParkingOnMap[]> => {
    const startTime: Date = this.getOrder().startTime;
    const endTime: Date = this.getOrder().endTime;
    const orderTimeSlot: timeSlot = this._datesService.datesToTimeSlot(
      startTime,
      endTime
    );

    // get all parkigns form the radius area that are not with details, or that are not cover current order's time slot:
    let parkingsNotToReload: ParkingOnMap[] = [];
    const parkingsIds = (await this.dataService.getParkings()).map((p) => p.id)
    console.log((await this.dataService.getParkings()).filter((p) => p.carsDetails != null));

    return this.getParkingsWithCarsAndTimeSlots(parkingsIds, isShowLoading).then(
      (parkings: ParkingOnMap[]) => {
        // filter parkingsNotToReload by order time slots, not take if it in parkings list(orderIdToIgnore):
        parkingsNotToReload = parkingsNotToReload
          .filter((parking) => !parkings.find((p) => p.id == parking.id))
          .map((parking) => {
            if (parking.carsDetails) {
              parking.carsDetails.forEach((car) => {
                car.availability = this.getCarAvailability(
                  car.timeSlots,
                  orderTimeSlot
                );
              });
            }
            return parking;
          });

        const p = parkings.concat(parkingsNotToReload);
        console.log('this.parkings[0]', this.dataService._parkings[0], p);
        return parkings.concat(parkingsNotToReload);
      }
    );
  };

  getParkingsWithCarsAndTimeSlots = async (
    parkingsIds: number[],
    isShowLoading: boolean = true
  ): Promise<ParkingOnMap[]> => {
    let parkings: ParkingOnMap[];
    let orderIdToIgnore: number;
    const startTime = this.getOrder().startTime;
    const endTime = this.getOrder().endTime;
    const orderTimeSlot = this._datesService.datesToTimeSlot(
      startTime,
      endTime
    );

    if (!parkingsIds.length) parkings = [];
    else {
      if (isShowLoading) this._loadingService.startLoading();

      parkings = (await this.dataService.getParkings())
      this.dataService._parkings = await this.mapParkingsWithDetails(
        parkings,
        orderIdToIgnore,
        startTime,
        endTime
      ) as ParkingOnMap[];
      console.log(parkings);

      // parkings to return to current call:
      const reloadedParkings: ParkingOnMap[] = this._globalService.copyObject(parkings);

      console.log('finish get cars availability');
      if (isShowLoading) {
        this._loadingService.stopLoading();
      }

      return reloadedParkings;
    }


    // filter, return only parkings with cars
    // allParkings = allParkings.filter(p => p.carsDetails && p.carsDetails.length);

    // return parkings, with used time slots of current order:
    parkings.forEach((parking) => {
      if (parking.carsDetails) {
        parking.carsDetails.forEach((car) => {
          car.availability = this.getCarAvailability(
            car.timeSlots,
            orderTimeSlot
          );
        });
      }
    });
    return parkings;
  }

  async getParkingById(parkingId: number): Promise<Parking> {
    const parking = (await this.dataService.getParkings()).find((p) => p.id == parkingId);
    if (!parking) {
      throw new Error('parking not found');
    }
    return parking;
  }

  private getParkingsCatchedTimeSolts(
    parkingIds: number[],
    startTime: Date,
    endTime: Date,
    orderIdToIgnore: number,
  ): Promise<any> {
    const body = <GetTimeSlotsBody>({
      Parkings: parkingIds,
      Range: {
        start: this._datepipe.transform(startTime, 'yyyy-MM-ddTHH:mm:ss'),
        end: this._datepipe.transform(endTime, 'yyyy-MM-ddTHH:mm:ss'),
      },
      orderIdToIgnore: orderIdToIgnore,
    } as unknown)
    return this._httpService.put(
      this._urlService.baseUrl() + 'timeSlots?pretty=1',
      body,
      true
    );
  }

  private mapParkingsWithDetails = async (
    parkings: Parking[],
    orderIdToIgnore: number,
    startTime: Date,
    endTime: Date,
  ): Promise<Parking[]> => {
    // add time slots to cars in parkings
    if (parkings && startTime && endTime) {
      parkings = this._timeSlotsPipe.insertTimeSlotsInParkings(
        parkings,
        startTime,
        endTime
      );

      return parkings;
    } else {
      return parkings;
    }
  };

  public async getParkingsByCityId(cityId: number): Promise<ParkingOnMap[]> {
    const city = this.dataService.cities.find((c) => c.id === cityId);
    if (city) {
      return (await this.dataService.getParkings()).filter((parking) => parking.name.slice(0, parking.name.indexOf('-')) === city.name)
    }
    throw new Error('עיר לא נמצאה');
  }

  public async filterParkingsByCityId(parkings: ParkingOnMap[], cityId: number): Promise<ParkingOnMap[]> {
    const city = this.dataService.cities.find((c) => c.id === cityId);
    if (city) {
      return parkings.filter((parking) => parking.name.slice(0, parking.name.indexOf('-')) === city.name)
    }
    throw new Error('עיר לא נמצאה');
  }

  async loadLastMaintenances(): Promise<any[]> {
    return this.lastMaintenances = await this._httpService.get<any>(this._urlService.nodeUrl() + 'cars');
  }

  loadWashCarFrequencies(): Observable<any[]> {
    console.log(this.dataService.permissions);
    return from(
      this._httpService.get<any>(
        this._urlService.nodeUrl() + 'cars/parkings/wash'
      )
    );
  }

  private getCarAvailability(
    carTimeSlots: timeSlot[],
    orderTimeSlot: timeSlot
  ): CarAvailability {
    return this._datesService.isTimeSlotsInTimesSlotsArray(
      carTimeSlots,
      orderTimeSlot,
    );
  }
}

<div id="result">
  <title>הזמנות</title>
  <app-page-title [pageTitle]="pageTitle"></app-page-title>
  <div class="title">
    <div class="col-xs-12 text-center">
      <h4 class="bluetext">
        נווט בלחיצה בין הזמנות עתידיות, הזמנות עבר והזמנה נוכחית
      </h4>
    </div>
  </div>
  <div
    *ngIf="selectedTab === 1"
    dir="rtl"
    class="border-div"
    [ngClass]="{
      'col-xs-offset-1': !isShowSmallFormat,
      'col-xs-10': !isShowSmallFormat,
      'col-xs-12': isShowSmallFormat
    }"
  >
    <h4 class="title-popup" translate>START_RANGE</h4>
    <app-date-time-picker
      [(ngModel)]="startTime"
      [isShowSmallFormat]="false"
    ></app-date-time-picker>

    <h4 class="title-popup" translate>END_RANGE</h4>
    <app-date-time-picker
      [(ngModel)]="endTime"
      [isShowSmallFormat]="false"
    ></app-date-time-picker>

    <button class="chooes-btn center-div" (click)="showHistory()" translate>
      SEARCH_ORDERS
    </button>
  </div>
  <section id="order" class="fullwidth" dir="rtl">
    <div class="col-xs-12 class-title tabs">
      <button
        [ngClass]="{ 'active-tab': selectedTab === 2 }"
        class="col-xs-4"
        (click)="showCarInRent()"
        translate
      >
        ACTIVE_ORDER
      </button>
      <button
        [ngClass]="{ 'active-tab': selectedTab === 1 }"
        class="col-xs-4"
        (click)="selectedTab = 1"
        translate
      >
        HISTORY
      </button>
      <button
        [ngClass]="{ 'active-tab': selectedTab === 0 }"
        class="col-xs-4 active-tab"
        (click)="showFuture()"
        translate
      >
        FUTURE_ORDERS
      </button>
    </div>
    <div class="routerContainer" style="text-align: center" *ngIf="emptyOrders">
      <h4>{{ message }}</h4>
    </div>
    <div class="routerContainer">
      <div *ngFor="let order of getOrderArray; let i = index">
        <ul (click)="openOrderDetails(i)" class="small-list-items new-style">
          <li>
            <div class="col-xs-1">
              <img
                *ngIf="i !== selectedIndex"
                class="img-arrow_left"
                src="assets/images/icon_arrow_bottom.png"
                alt=""
              />
              <img
                *ngIf="i === selectedIndex"
                class="img-arrow_left"
                src="assets/images/icon_arrow_top.png"
                alt=""
              />
            </div>
            <div class="col-xs-2 flush new_page_onelist class-color">
              <p>
                <b>{{
                  OfficeOrderTypeDescriptionHeEnum[order['officeDriveType']]
                }}</b>
              </p>
              <p>
                <b>{{ order['officeDriveReason'] }}</b>
              </p>
            </div>

            <div class="col-xs-6 new_page_onelist">
              <p>
                <b
                  >{{ 'FROM' | translate }}
                  {{ order.startTime | customDate: 'dd/MM/yyyy HH:mm' }}</b
                >
              </p>
              <!-- <p *ngIf="order.isCancelled">בוטלה</p> -->
              <p>
                <b
                  >{{ 'FINISH_AT' | translate }}
                  {{ order.endTime | customDate: 'dd/MM/yyyy HH:mm' }}</b
                >
              </p>
            </div>
            <div class="col-xs-3 new_page_onelist">
              <p>
                {{ order.startParking.name }}
              </p>
            </div>
          </li>
        </ul>
        <div *ngIf="i === selectedIndex" class="orderDetails">
          <div class="finish_order_modal">
            <div class="total_exclude_modal_body">
              <div class="exclude_modal_body_wrapper" dir="ltr">
                <div class="exclude_modal_body_top">
                  <div class="exclud_without_top_right">
                    <h5 *ngIf="order.startParking">
                      {{ order.startParking.name }}
                    </h5>
                  </div>
                  <div class="exclud_top_left"></div>
                </div>
                <div class="exclude_modal_body_botom">
                  <div class="exclude_midle_left">
                    <img src="assets/images/icon_clock_bluel.png" alt="" />
                    <h2 translate>{{ 'FINISH' | translate }}</h2>
                    <h5>{{ order.endTime | customDate: 'dd/MM/yyyy' }}</h5>
                    <p>{{ order.endTime | customDate: 'HH:mm' }}</p>
                  </div>
                  <div class="exclude_midle_right">
                    <img src="assets/images/icon_clock_bluer.png" alt="" />
                    <h2 translate>{{ 'START' | translate }}</h2>
                    <h5>{{ order.startTime | customDate: 'dd/MM/yyyy' }}</h5>
                    <p>{{ order.startTime | customDate: 'HH:mm' }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!--test-->
            <div *ngIf="order.orderStatusHebrew !== 'בוטלה'">
              <div class="center-div">
                <h4 class="h4-exclude" style="margin-left: 5px">
                  {{ 'TIME_OF_RENT' | translate }}: {{ order.timeAmount }}
                  {{ order.periodName }} |
                </h4>
                <h4
                  class="h4-exclude"
                  *ngIf="
                    order.status === 1 ||
                    order.status === 2 ||
                    order.status === 3 ||
                    order.status === 4 ||
                    order.status === 9
                  "
                  class="h4-exclude"
                >
                  {{ 'FOR_PAYMENT' | translate }}: ₪ {{ order.finalPrice }}
                </h4>
                <h4
                  class="h4-exclude"
                  *ngIf="
                    order.status !== 1 &&
                    order.status !== 2 &&
                    order.status !== 3 &&
                    order.status !== 4 &&
                    order.status !== 9
                  "
                >
                  {{ 'FOR_PAYMENT' | translate }}:
                  {{ 'NOT_FINAL_PRICE' | translate }}
                </h4>
              </div>

              <div
                class="center-div"
                *ngIf="order.orderStatusHebrew === 'חויבה'"
              >
                <button
                  (click)="getPriceDetails = !getPriceDetails"
                  class="btn-purple"
                >
                  {{ 'BILLING_INFORMATION' | translate }}
                </button>
              </div>

              <div
                class="price-detailes"
                *ngIf="
                  order.orderStatusHebrew === 'חויבה' &&
                  getPriceDetails === true
                "
              >
                <table class="table table-bordered h6-exclude">
                  <thead>
                    <tr>
                      <th>{{ 'DESCRIPTION' | translate }}</th>
                      <th>{{ 'COST' | translate }}</th>
                      <th>{{ 'QUANTITY' | translate }}</th>
                      <th>{{ 'TOTAL' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style="direction: rtl"
                      *ngFor="let detail of order.priceDetails"
                    >
                      <td>{{ detail.detailsTypeHebrew }}</td>
                      <td>{{ detail.pricePerUnit }}</td>
                      <td>{{ detail.multiplier }}</td>
                      <td>₪ {{ detail.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="center-div" *ngIf="selectedTab === 0">
              <button
                (click)="editOrder()"
                class="btn-purple"
                style="margin-left: 5px"
                translate
              >
                EDIT
              </button>
              <button (click)="cancellOrder()" class="btn-purple" translate>
                CANCEL
              </button>
            </div>
            <div
              class="center-div"
              *ngIf="!order.isCancelled"
              style="margin-top: 3.5px"
            >
              <div *ngIf="selectedTab === 1">
                <!-- <button  class="btn-purple"> שלח לי קבלה</button> -->
                <button
                  [disabled]="!order.isEnableSendTravelDetails"
                  class="btn-purple"
                  (click)="sendTravelDetails(order.id)"
                  translate
                >
                  SEND_TO_MAIL
                </button>
              </div>

              <div style="padding-left: 70px" *ngIf="selectedTab === 2">
                <button
                  (click)="editCurrentOrder(i)"
                  class="btn-purple"
                  style="margin-left: 5px"
                  translate
                >
                  עבור להזמנה
                </button>
              </div>
              <div>
                <button
                  (click)="sendEmail(order.id)"
                  class="contact-btn"
                  style="margin-left: 5px"
                >
                  <img
                    class="img-contact"
                    src="assets/images/mail-contact.png"
                  />
                  {{ 'SEND_APPLICTION_ON_ORDER' | translate }}
                </button>
              </div>
            </div>
            <div *ngIf="isIn24HoursOfOrder(i)" class="center-div">
              <button
                (click)="markAsWashed(i)"
                class="contact-btn"
                style="margin-left: 5px"
              >
                סמן רכב כנשטף
              </button>
            </div>

            <div class="row center-div" *ngIf="order.isOfficeDrive">
              <div class="imagesDescription">
                <div class="damageDiv">
                  <div class="col-xs-12">
                    <image-upload
                      dir="ltr"
                      [buttonCaption]="'העלה תמונות'"
                      [dropBoxMessage]="'גרור לכאן תמונות'"
                      [clearButtonCaption]="'הסר הכל'"
                      [extensions]="['jpeg', 'png']"
                      (uploadFinished)="onUploadFinished($event, 'front')"
                      (removed)="onRemoved()"
                    ></image-upload>
                  </div>
                  <br />

                  <div class="col-xs-12">
                    <div class="imagesDescription">
                      <div
                        class="col-xs-6 damageDiv"
                        *ngFor="let image of images; let i = index"
                      >
                        <div style="margin-top: -3px">
                          <div class="imageDiv" style="border: black 2px solid">
                            <img width="100%" class="col-xs-12" [src]="image" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div
                    class="col-xs-12 center-div"
                    style="margin-top: 25px; margin-bottom: 10px"
                  >
                    <button
                      class="blue-btn-class center-div"
                      (click)="sendImage(order)"
                    >
                      שלח תמונות
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div [ngClass]="{ 'has-error-parent': isHasError }" id="result">
  <title>סיטי קאר - יצירת הזמנה</title>

  <app-page-title [pageTitle]="pageTitle"></app-page-title>
  <!-- #page-title ends -->
  <app-order-details [order]="order"></app-order-details>
  <!-- #items ends -->
  <div style="height: 100px" class="col-xs-12"></div>
  <section
    *ngIf="order.finalPrice"
    class="footer_pickup_area order fullwidth"
    dir="rtl"
  >
    <div class="routerContainer">
      <div class="top col-xs-12 text-center">
        <p class="bluetext col-xs-offset-2 col-xs-8">
          <b *ngIf="_orderService.isShowPrice">
            {{ 'TOTAL' | translate }} {{ order.finalPrice }} ₪
            {{ 'PRICE_INCLUDE' | translate }}
          </b>
        </p>
        <div class="col-xs-12 text-center" style="padding: 0">
          <div class="flush">
            <button
              *ngIf="!isEditOrder"
              [disabled]="!enableFinishOrder"
              (click)="finishOrder()"
              type="button"
              class="pickup_approval col-xs-12"
              id="modaldisable"
              data-toggle="modal"
              data-target="#myModal3"
            >
              {{ 'CONFIRM_BTN' | translate }}
            </button>
            <button
              *ngIf="isEditOrder"
              (click)="updateOrder()"
              type="button"
              class="pickup_approval col-xs-12"
              id="modaldisable"
              data-toggle="modal"
              data-target="#myModal3"
            >
              שמור שינויים
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    *ngIf="!order.finalPrice"
    class="footer_pickup_area order fullwidth"
    dir="rtl"
  >
    <div class="routerContainer">
      <div class="top col-xs-12 text-center">
        <p class="col-xs-offset-2 col-xs-8">
          <b
            >אירעה שגיאה בחישוב המחיר, נסה לבצע שנית את ההזמנה או צור קשר עם
            נציג שירות
          </b>
        </p>
        <!-- <a type="button" class="btn btn-lg pickup_fivehundred">{{order.finalPrice}} ₪</a> -->
      </div>
    </div>
  </section>
</div>

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { last } from "rxjs/operators";
import { City } from "../models/city";
import { ParkingOnMap } from "../models/parking";
import { User, UserPermission } from "../models/user";
import { AppGlobals } from "../shared/app-globals/app-globals";
import { AlertService } from "./alert.service";
import { HttpService } from "./http.service";
import { UrlService } from "./url.service";
import { EmployeeType, UserMode, UserService } from "./user.service";

export interface EMPLOYEE {
  ID: number;
}

export interface ParkingWashAndMaintenanceDetails {
  _id: string;
  PARKING_ID: number;
  WASH_FREQUENCY_IN_DAYS: number;
  MAINTENANCE_FREQUENCY_IN_DAYS: number;
  EMPLOYEES: EMPLOYEE[];
}

@Injectable({ providedIn: 'root' })
export class DataService {
  _user: User;
  washPermittedParkings: ParkingOnMap[] = [];
  maintainPermittedParkings: ParkingOnMap[] = [];
  maintainPermittedCities: City[] = [];
  washPermittedCities: City[] = [];
  permissions: UserPermission; lastMaintenances: any;
  cities: City[];
  public _parkings: ParkingOnMap[];
  parkingsToWash: ParkingOnMap[] = [];
  parkingsToMaintain: ParkingOnMap[] = [];

  loadParkings = async (): Promise<ParkingOnMap[]> => {
    const parkings = await this.httpService.put<{ parkingsWithCars: ParkingOnMap[] }>(
      this.urlService.baseUrl() + 'united'
    );
    if (parkings?.parkingsWithCars) {
      this._parkings = parkings.parkingsWithCars;
      return this._parkings;
    }
    return this._parkings;
  };


  loadCities = async (): Promise<City[]> => {
    const cities = await this.httpService.get<City[]>(
      this.urlService.baseUrl() + 'parkings'
    );
    return this.cities = cities as City[];
  };

  loadWashPermittedParkings = async (): Promise<ParkingOnMap[]> => {
    const washPermittedParkingsIds = await this.loadUserPermissions(EmployeeType.Wash);
    return this.washPermittedParkings = this.permittedParkingsFromIds(washPermittedParkingsIds);
  };

  loadMaintainPermittedParkings = async (): Promise<ParkingOnMap[]> => {
    const maintenancePermittedParkingsIds = await this.loadUserPermissions(EmployeeType.Maintenance);
    return this.maintainPermittedParkings = this.permittedParkingsFromIds(maintenancePermittedParkingsIds);
  };

  private permittedParkingsFromIds(permittedParkingsIds: { id: number; }[]) {
    const permittedParkingIds = permittedParkingsIds?.map((p_p) => p_p.id);
    return this._parkings.filter((p) => permittedParkingIds?.includes(p.id));
  }

  loadUserPermissions(employeeType: EmployeeType): Promise<{ id: number; }[]> {
    return this.httpService.post<{ id: number }[]>(
      this.urlService.nodeUrl() + 'api/users/permissions',
      {
        UserId: this.permissions.userID,
        Type: employeeType,
      }
    ) as Promise<{ id: number; }[]>;
  }

  public get permittedCities(): City[] {
    return this.userService.userMode === UserMode.Wash ? this.washPermittedCities : this.maintainPermittedCities;
  }
  public get permittedParkings(): ParkingOnMap[] {
    return this.userService.userMode === UserMode.Wash ? this.washPermittedParkings : this.maintainPermittedParkings;
  }

  public get workNeededParkings(): ParkingOnMap[] {
    return this.userService.userMode === UserMode.Wash ? this.parkingsToWash : this.parkingsToMaintain;
  }

  constructor(private userService: UserService, private httpService: HttpService, private urlService: UrlService, private translateService: TranslateService, private alertService: AlertService) { }

  initData() {
    // load user
    this.getUser()
      .then(async () => {
        this.userService.SetEmployeeType(this.permissions);

        // load parkings
        await this.loadParkings();
        // load cities Permissions
        await Promise.all([
          // load wash permitted cities
          this.loadUserPermissions(EmployeeType.Wash).then(maintenancePermittedParkingsIds => {
            this.washPermittedParkings = this.permittedParkingsFromIds(maintenancePermittedParkingsIds);
          }),
          // load maintenance permitted cities
          this.loadUserPermissions(EmployeeType.Maintenance).then(maintenancePermittedParkingsIds => {
            this.maintainPermittedParkings = this.permittedParkingsFromIds(maintenancePermittedParkingsIds);
          }),
        ]);
        // load list of all available cities
        this.cities = await this.loadCities();

        // get allowed to maintain cities and save them in a variable
        const maintainPermittedCitiesId = new Set(this.maintainPermittedParkings.map((p) => p.cityId));
        this.maintainPermittedCities = this.cities.filter((c) => maintainPermittedCitiesId.has(c.id));

        // get allowed to wash cities and save them in a variable
        const washPermittedCitiesId = new Set(this.washPermittedParkings.map((p) => p.cityId));
        this.washPermittedCities = this.cities.filter((c) => washPermittedCitiesId.has(c.id));

        // load wash car frequencies
        const washCarFrequencies = await this.loadWashCarFrequencies()
        await this.fillFrequenciesInfoOrDefaultOnParkings(washCarFrequencies);


        // parkings that should be shown in the map to wash
        this.parkingsToWash = this.filterByFrequencyDate(this._parkings, EmployeeType.Wash);
        // parkings that should be shown in the map to maintain
        this.parkingsToMaintain = this.filterByFrequencyDate(this._parkings, EmployeeType.Maintenance);
      });
  }

  private async fillFrequenciesInfoOrDefaultOnParkings(washFrequencies: ParkingWashAndMaintenanceDetails[]) {
    const parkings = await this.getParkings();
    parkings.forEach((p) => {
      const parking = washFrequencies.find((r) => r.PARKING_ID == p.id);

      parking && parking.WASH_FREQUENCY_IN_DAYS > 0
        ? p.washFrequencyInDays = parking.WASH_FREQUENCY_IN_DAYS
        : AppGlobals.FREQUENCIES_DEFAULT_VALUES.WASH;
      parking && parking.MAINTENANCE_FREQUENCY_IN_DAYS > 0
        ? p.maintenanceFrequencyInDays = parking.MAINTENANCE_FREQUENCY_IN_DAYS
        : AppGlobals.FREQUENCIES_DEFAULT_VALUES.MAINTENANCE;
    });

    this._parkings = parkings;
  }

  filterByFrequencyDate(parkings: ParkingOnMap[], employeeType: EmployeeType): ParkingOnMap[] {
    if (employeeType === EmployeeType.Maintenance) {
      this.fillLastMaintenanceInfo();
      return parkings.filter(
        (p) =>
          !p.carsDetails ||
          p.carsDetails.some(
            (c) =>
              !c.lastMaintenanceDate ||
              moment(c.lastMaintenanceDate).add(p.maintenanceFrequencyInDays, 'days') < moment()
          )
      );
    } else {
      return parkings.filter(
        (p) =>
          !p.carsDetails ||
          p.carsDetails.some(
            (c) =>
              !c.lastWashDate ||
              moment(c.lastWashDate).add(p.washFrequencyInDays, 'days') < moment()
          )
      );
    }
  }

  public async fillLastMaintenanceInfo() {
    if (!this.lastMaintenances) {
      await this.loadLastMaintenances();
    }

    this.lastMaintenances.forEach((car) => {
      const parking = this._parkings.find((p) => p.id === car.stationId);
      if (parking) {
        const carDetails = parking.carsDetails.find(
          (c) => c.carNumber === Number(car.carNumber)
        );
        if (carDetails) {
          carDetails.lastMaintenanceDate = car.lastMaintinceCheck;
        }
      }
    });
    console.log(
      this._parkings.filter((p) =>
        p.carsDetails.some((c) => c.lastMaintenanceDate != null)
      )
    );
  }

  async loadLastMaintenances(): Promise<any[]> {
    return this.lastMaintenances = await this.httpService.get<any>(this.urlService.nodeUrl() + 'cars');
  }

  // todo
  loadWashCarFrequencies(): Promise<ParkingWashAndMaintenanceDetails[]> {
    console.log(this.permissions);
    return this.httpService.get<any>(
      this.urlService.nodeUrl() + 'cars/parkings/wash'
    );
  }

  loadUserAndSetEmployType = async (
    isShowLoading: boolean = true,
  ): Promise<UserPermission | null> => {
    try {
      const user = await this.httpService.get<UserPermission>(
        this.urlService.baseUrl() + 'users/a',
        isShowLoading
      );
      if (user) {
        this.permissions = user;
        this.userService.SetEmployeeType(this.permissions);
        this.userService.setUserModeByEmployeeType(this.userService.userType);
        return user;
      }
      return null;
    } catch (error: any) {
      this.translateService
        .get('MESSAGES.GET_USER_DETAILS_ERROR_MESSAGE')
        .pipe(last())
        .subscribe((message) => {
          this.alertService.error(message, true);
        });

      console.log(`Error:+ ${error}`);
      this.alertService.error(error);
      return null;
    }
  }

  async getUser(): Promise<UserPermission> {
    return this.permissions ?? this.loadUserAndSetEmployType();
  }

  async getCities(): Promise<City[]> {
    return this.cities || this.loadCities();
  }

  async getParkings(): Promise<ParkingOnMap[]> {
    return this._parkings || this.loadParkings();
  }


  // GET https://proxy1.citycar.co.il/api/users/a
  // POST https://proxy1.citycar.co.il/api/parkings/FullDetails?pretty=1
  // GET https://node.citycar.co.il/cars/parkings/wash
}

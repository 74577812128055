import {
  Component,
  OnInit,
  forwardRef,
  Input,
} from '@angular/core';
import { GlobalService } from '../../services/global.service';

import {
  NG_VALUE_ACCESSOR,
  NgForm,
} from '@angular/forms';
import { FileHolder } from 'angular2-image-upload';
import { AppGlobals } from '../app-globals/app-globals';

const noop = () => { };

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ImageInputComponent),
  multi: true,
};

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class ImageInputComponent implements OnInit {
  private _baes64String: string | null;
  type: boolean | null = true;

  @Input()
  pictureText: string;

  @Input()
  mobilePictureText: string;

  // @Input()
  // uploadText: string;

  @Input()
  f: NgForm;

  @Input()
  maxImageSizeKb: number = AppGlobals.IMAGES_DATA.MAX_SIZE_KB.DAMAGE_REPORT;

  @Input()
  isRequired = false;

  get customStyle() {
    return AppGlobals.STYLES.IMAGE_UPLOAD;
  }

  get isPictureSizeValid(): boolean {
    return !(
      this.baes64String &&
      this.maxImageSizeKb <
      this._globalService.calculateFile64SizeKB(
        this.baes64String,
        'data:image/png;base64,'
      )
    );
  }

  get isPictureValid(): boolean {
    return !((!this.type && this.type != null) ||
      !this.isPictureSizeValid ||
      (this.isRequired && this.f && this.f.submitted && !this.baes64String));


  }

  get takePictureText(): string {
    return 'צלם תמונה';
  }

  get lablePictureText(): string {
    return 'תמונת ' + this.pictureText;
  }

  // ngModel properties:

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  isValid() {
    if (this.baes64String.indexOf('png') == -1) console.log('not png');
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this._baes64String) {
      this._baes64String = value;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public get baes64String(): string | null {
    return this._baes64String;
  }
  public set baes64String(v: string | null) {
    this._baes64String = v;
    this.onChangeCallback(v);
  }

  constructor(
    private _globalService: GlobalService,
  ) { }

  ngOnInit() {
    console.log(this.type);
  }

  onUploadFinished(event: FileHolder) {
    this.baes64String = event.src;
    const fileType = event.file.type;
    this.type = fileType.indexOf('image') != -1;
  }

  onRemoved() {
    this.type = null;
    this.baes64String = null;
  }
}

import { CarAvailability } from '../pipes/time-slots.pipe';

export class CarStatus {
  constructor(public used: Used | null = null, public carStatusType: CarAvailability = CarAvailability.FREE) { }
}

export class CarInParking {
  amountOfSeats: number;
  carCategory: number;
  carGearType: string;
  carType: string;
  gasType: string;
  isA2BCar: boolean;
  lastWashDate: string;
  lastMaintenanceDate: Date;
  availability: CarAvailability;
  carNumber: number;
  errorMessage: string;
  timeSlots: timeSlot[];
  needsRecall: boolean;
  maxPreorder: string;
}

export interface AtParking {
  start: string;
  end: string;
}

export interface Used {
  odt: number;
  start: string;
  end: string;
  markedAsFinished: boolean;
}

export class timeSlot {
  atParking: AtParking;
  used: Used[];
}

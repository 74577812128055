
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  LocationStrategy,
  HashLocationStrategy,
  DatePipe,
} from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { routes } from './routers/router';

import { AppComponent } from './app.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { ReplaySessionService } from './services/replay-session.service';
import OpenReplay from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';

import {
  HomeComponent,
  AccidentReportingComponent,
  CarComponent,
  DealsComponent,
  LocationComponent,
  MenuComponent,
  OrderListComponent,
  OrderSummaryComponent,
  OrderUpdateComponent,
  SelectTimeComponent,
  StationsInMapComponent,
  OrderTimeComponent,
  OrderPeriodComponent,
  FinishOrderComponent,
} from './pages';

import { AuthGuard, IsDataExistsGuard } from './guards';

import { ModalModule } from 'ngx-modialog-7';
import { OrderDatailsExistGuard } from './guards/order-datails-exist.guard';
import { OrderDateEditorComponent } from './shared/order-date-editor/order-date-editor.component';
import { DynamicContainerComponent } from './directives';
import { DateTimePickerComponent } from './directives/date-time-picker/date-time-picker.component';
import { ValidatorsListPipe } from './pipes/validators-list.pipe';
import { ValidatorMessagesComponent } from './directives/validator-messages/validator-messages.component';
import { AppStartService } from './services/app-start.service';
import { CarInRentComponent } from './modals/car-in-rent/car-in-rent.component';
import { CarAvailabilitySuggestionPipe } from './pipes/car-availability-suggestion.pipe';
import { ContactComponent } from './pages/contact/contact.component';
import { OrderDetailsComponent } from './shared/order-details/order-details.component';
import { EditOrderComponent } from './pages/edit-order/edit-order.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { ImproveCancellOrderComponent } from './modals/improve-cancell-order/improve-cancell-order.component';
import { TimeSlotsPipe } from './pipes/time-slots.pipe';
import { DamageReportComponent } from './pages/damage-report/damage-report.component';

import { ImageUploadModule } from 'angular2-image-upload';
import { DamageReportResolver, OpenOrderResolver } from './resolvers';
import { LoadDataService } from './services/load-data.service';
import { GlobalService } from './services/global.service';
import { ModalsHeaderComponent } from './modals/modals-header/modals-header.component';
import { DamageReportTypeComponent } from './modals/damage-report-type/damage-report-type.component';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { RequiredDirective } from './directives/required.directive';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ImageInputComponent } from './shared/image-input/image-input.component';
import { EnumToSelectGroupPipe } from './pipes/enum-to-select-group.pipe';
import { UrlService } from './services/url.service';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import {
  bootstrap3Mode,
  BootstrapModalModule,
  Modal,
} from 'ngx-modialog-7/plugins/bootstrap';
import { FullDateDirective } from './directives/full-date.directive';
import { MonthInputComponent } from './shared/month-input/month-input.component';
import { LoadinExtraDataComponent } from './modals/loadin-extra-data/loadin-extra-data.component';
import { IrregularOrderService } from './services/irregular-order.service';
import { StatusComponent } from './pages';
import { MergePipe } from './pipes/merge.pipe';
import { UpdateCreditCardComponent } from './modals/update-credit-card/update-credit-card.component';
import { UploadsService } from './services/uploads.service';
import { SearchByOptionsComponent } from './modals/search-by-options/search-by-options.component';
import { CarAvailabilityPipe } from './pipes/car-availability.pipe';
import { ModeMenuComponent } from './pages/mode-menue/mode-menu.component';
import { SelectOfficeDriveTypeComponent } from './modals/select-office-drive-type/select-office-drive-type.component';
import { ReportMaintenanceComponent } from './modals/report-maintenance/report-maintenance.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AlertService } from './services/alert.service';
import { DatesService } from './services/dates.service';
import { GoogleAuthService } from './services/google-auth.service';
import { GoogleMapsService } from './services/google-maps.service';
import { HttpService } from './services/http.service';
import { LoadingService } from './services/loading.service';
import { MessagesService } from './services/messages.service';
import { ModalService } from './services/modal.service';
import { OrderService } from './services/order.service';
import { ParkingsService } from './services/parkings.service';
import { ServicesCommunicationService } from './services/services-communication.service';
import { StatusService } from './services/status.service';
import { UserService } from './services/user.service';
import { AlertComponent } from './shared/alert/alert.component';
import { CarDetailsComponent } from './shared/car-details/car-details.component';
import { GlobalErrorHandlerComponent } from './shared/global-error-handler/global-error-handler.component';
import { HeaderComponent } from './shared/header/header.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { LoginComponent } from './shared/login/login.component';
import { PageTitleComponent } from './shared/page-title/page-title.component';
import { PlaceAutocompleteComponent } from './shared/place-autocomplete/place-autocomplete.component';
import { SpeechBubbleComponent } from './shared/speech-bubble/speech-bubble.component';
import { FinishOrderDetailsComponent } from './modals/finish-order-details/finish-order-details.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';
import { MatButtonModule } from '@angular/material/button';

export function createTranslateLoader(http: HttpClient) {
  console.log('APP_BASE_HREF', document.getElementsByTagName('base')[0].href);
  return new TranslateHttpLoader(http);
}

// tracer init
const tracker = new OpenReplay({
  projectKey: 'FfoyZkYmiIBFXGjhQrUo',
  __DISABLE_SECURE_MODE: true,
});
tracker.use(trackerAssist({ confirmText: 'Live assistant' }));
tracker.start();
tracker.metadata('version', '4');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    LocationComponent,
    CarComponent,
    AccidentReportingComponent,
    DealsComponent,
    OrderListComponent,
    OrderSummaryComponent,
    OrderUpdateComponent,
    StationsInMapComponent,
    OrderPeriodComponent,
    SelectTimeComponent,
    OrderTimeComponent,
    PlaceAutocompleteComponent,
    HeaderComponent,
    LoginComponent,
    PageTitleComponent,
    LoadingComponent,
    AlertComponent,
    FinishOrderComponent,
    CarDetailsComponent,
    GlobalErrorHandlerComponent,
    FinishOrderDetailsComponent,
    OrderDateEditorComponent,
    DynamicContainerComponent,
    DateTimePickerComponent,
    ValidatorsListPipe,
    ValidatorMessagesComponent,
    CarInRentComponent,
    CarAvailabilitySuggestionPipe,
    ContactComponent,
    OrderDetailsComponent,
    EditOrderComponent,
    UserProfileComponent,
    MessagesComponent,
    ImproveCancellOrderComponent,
    TimeSlotsPipe,
    DamageReportComponent,
    ModalsHeaderComponent,
    DamageReportTypeComponent,
    AlertModalComponent,
    RequiredDirective,
    ImageInputComponent,
    EnumToSelectGroupPipe,
    CustomDatePipe,
    SpeechBubbleComponent,
    FullDateDirective,
    MonthInputComponent,
    LoadinExtraDataComponent,
    StatusComponent,
    MergePipe,
    UpdateCreditCardComponent,
    SearchByOptionsComponent,
    CarAvailabilityPipe,
    ModeMenuComponent,
    SelectOfficeDriveTypeComponent,
    ReportMaintenanceComponent,
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAAR6ZQqp4fLncby3R0mocpQu-yKZVz9Zo',
      libraries: ['places'],
    }),
    AgmDirectionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgSelectModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    ImageUploadModule.forRoot(),
    ModalModule.forRoot(),
    BootstrapModalModule,
    TranslateModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    NgbModule,
    MatButtonModule,
    AgmDirectionModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    DatePipe,
    CustomDatePipe,
    GoogleAuthService,
    GoogleMapsService,
    HttpService,
    OrderService,
    ParkingsService,
    UserService,
    MessagesService,
    LoadingService,
    AlertService,
    UrlService,
    AuthGuard,
    OrderDatailsExistGuard,
    IsDataExistsGuard,
    { provide: DatePipe, useClass: CustomDatePipe },
    ValidatorsListPipe,
    CarAvailabilitySuggestionPipe,
    CarAvailabilityPipe,
    TimeSlotsPipe,
    GlobalService,
    EnumToSelectGroupPipe,
    Modal,
    ModalService,
    DatesService,
    IrregularOrderService,
    StatusService,
    UploadsService,
    AppStartService,
    DamageReportResolver,
    OpenOrderResolver,
    ServicesCommunicationService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    LoadDataService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerComponent },
    TranslateService,
    ReplaySessionService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [BootstrapModalModule],
})
export class AppModule {
  constructor(
    private _appStartService: AppStartService,
    private replaySessionService: ReplaySessionService
  ) {
    this.replaySessionService.tracker = tracker;
    bootstrap3Mode();
    setTimeout(() => {
      _appStartService.init();
    }, 100);
  }
}

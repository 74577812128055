import { Pipe, PipeTransform, Inject, forwardRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DatesService } from '../services/dates.service';
import { GlobalService } from '../services/global.service';

import { timeSlot, CarInParking, Used } from '../models/car';
import { Order } from '../models/order';
import { Parking } from '../models/parking';
import { AppGlobals } from '../shared/app-globals/app-globals';
import { newDate } from '../shared/function';
import * as moment from 'moment';

export enum CarAvailability {
  FREE = 'FREE',
  OCCUPIED = 'OCCUPIED',
  OVERLAPPING = 'OVERLAPPING',
  NOT_APPLIABLE = 'NOT_APPLIABLE',
}

export class carTimeSlots {
  carId: number;
  atParking: timeSlot;
  used: timeSlot[];
}

export class parkingTimeSlots {
  parkingId: number;
  carTimeSlots: carTimeSlots[];
}

@Pipe({
  name: 'TimeSlots',
})
export class TimeSlotsPipe implements PipeTransform {
  currentOrderTimeSlot: timeSlot;

  getOrder: () => Order;

  constructor(
    private _datepipe: DatePipe,

    @Inject(forwardRef(() => DatesService))
    private _datesService: DatesService,

    private _globalService: GlobalService
  ) { }

  transform(value: any, ...args: any[]) {
    throw new Error('Method not implemented.');
  }

  registerFromOrderService(getOrder: () => Order) {
    this.getOrder = getOrder;
  }

  insertTimeSlotsInParkings(
    parkings: Parking[],
    startOrderTime: Date,
    endOrderTime: Date
  ): Parking[] {
    if (!parkings) return [];
    parkings.forEach((parking: Parking) => {
      parking.carsDetails.forEach((car: CarInParking) => {
        this.updateCarAvailability(car, startOrderTime, endOrderTime);
      });
    });

    return parkings;
  }

  updateCarAvailability(
    car: CarInParking,
    startOrderTime: Date,
    endOrderTime: Date
  ): CarAvailability {
    return car.availability = !car.timeSlots ? CarAvailability.FREE : this.getNotAvailableTimeSlots(car, startOrderTime, endOrderTime);
  }

  private getNotAvailableTimeSlots(
    car: CarInParking,
    startOrderTime: Date,
    endOrderTime: Date
  ): CarAvailability {
    return this.getCarStatusByTimeSlots(car.timeSlots, newDate(car.maxPreorder), startOrderTime, endOrderTime);
  }

  private getCarStatusByTimeSlots(
    timeSlotsList: timeSlot[],
    maxPreOrder: Date,
    startOrderTime: Date,
    endOrderTime: Date
  ): CarAvailability {
    let carStatus = CarAvailability.FREE;

    // if (maxPreOrder != null) {
    //   const intervalInDays = moment(maxPreOrder).diff(moment(startOrderTime), 'days');
    //   if (intervalInDays < 0) {
    //     return CarAvailability.NOT_APPLIABLE;
    //   }
    // }

    for (const timeSlots of timeSlotsList) {
      const usedList = timeSlots.used ?? [];
      for (const used of usedList) {
        if (this.isUsedInConflict(startOrderTime, endOrderTime, used)) {
          carStatus = CarAvailability.OVERLAPPING;
          if (this.isUsedUnavailable(startOrderTime, endOrderTime, used)) {
            carStatus = CarAvailability.OCCUPIED;
          }
          return carStatus;
        }
      }
    }
    return CarAvailability.FREE;
  }

  isUsedInConflict(startDate: Date, endDate: Date, used: Used): boolean {
    const usedStartMinusFiveMinutes = moment(used.start ?? '').subtract(5, 'minutes').toDate();
    const usedEndPlusFiveMinutes = moment(used.end ?? '').add(5, 'minutes').toDate();
    const isStartOrderInConflict = moment(startDate).isBetween(usedStartMinusFiveMinutes, usedEndPlusFiveMinutes);
    const isEndOrderInConflict = moment(endDate).isBetween(usedStartMinusFiveMinutes, usedEndPlusFiveMinutes);

    if (isEndOrderInConflict || isStartOrderInConflict) {
      return true;
    }
    return false;
  }

  isUsedUnavailable(startDate: Date, endDate: Date, used: Used): boolean {
    const usedStartMinusFiveMinutes = moment(used.start ?? '').subtract(5, 'minutes').toDate();
    const usedEndPlusFiveMinutes = moment(used.end ?? '').add(5, 'minutes').toDate();
    const isEndOrderBeforeEndUsed = moment(endDate).isBefore(usedEndPlusFiveMinutes);
    const isStartOrderAfterStartUsed = moment(startDate).isAfter(usedStartMinusFiveMinutes);

    if (isEndOrderBeforeEndUsed && isStartOrderAfterStartUsed) {
      return true;
    }
    return false;
  }

  //not implemented,
  //merge array of time slots, contact closed time slots, delete duplicate cont...
  mergeTimeSlotsArray(timeSlots: timeSlot[]): timeSlot[] {
    if (!timeSlots) return null;
    function merge(ranges) {
      const result = [];
      let last;

      ranges.forEach(function (r) {
        if (r.start != r.end) {
          if (!last || newDate(r.start).getTime() > newDate(last.end).getTime())
            result.push((last = r));
          else if (newDate(r.end).getTime() > newDate(last.end).getTime())
            last.end = r.end;
        }
      });

      return result;
    }

    timeSlots.sort(function (a, b) {
      return (
        newDate(a.atParking.start).getTime() - newDate(b.atParking.start).getTime() ||
        newDate(a.atParking.end).getTime() - newDate(b.atParking.end).getTime()
      );
    });

    return merge(timeSlots);
  }

  concatTimeSlotsOfLessThenHourBetween(
    timeSlots: timeSlot[],
    startOrderTime: Date,
    endOrderTime: Date
  ): timeSlot[] {
    if (!timeSlots) return null;

    const newTimeSlots: timeSlot[] = [];
    let previousEndTime: number;
    for (let i = 0; i < timeSlots.length; i++) {
      let timeSlotToAdd: timeSlot;
      const currentStartTime = newDate(timeSlots[i].atParking.start).getTime();

      //if the space between this time slot from last time slot is less the hour:
      if (
        previousEndTime &&
        currentStartTime - previousEndTime < AppGlobals.TIMES.HOURS_IN_TIME
      ) {
        const timeSlot = newTimeSlots.pop();
        timeSlotToAdd = <timeSlot>{
          atParking: {
            start: timeSlot?.atParking.start,
            end: timeSlots[i].atParking.end,
          }
        };

        //if new time slot covers order time slot:
        if (
          newDate(timeSlotToAdd.atParking.start).getTime() <= startOrderTime.getTime() &&
          newDate(timeSlotToAdd.atParking.end).getTime() >= endOrderTime.getTime()
        )
          return null;
      } else timeSlotToAdd = this._globalService.copyObject(timeSlots[i]);

      newTimeSlots.push(timeSlotToAdd);
      previousEndTime = newDate(timeSlots[i].atParking.end).getTime();
    }
    return newTimeSlots;
  }
}

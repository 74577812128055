import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Parking } from '../../models/parking';
import { GlobalService } from '../../services/global.service';
import { CarInParking } from '../../models/car';
import { AppGlobals } from '../app-globals/app-globals';
import { UserMode } from '../../services/user.service';

@Component({
  selector: 'app-car-details',
  templateUrl: './car-details.component.html',
  styleUrls: ['./car-details.component.css'],
})
export class CarDetailsComponent implements OnInit {
  UserMode: typeof UserMode = UserMode;

  @Input()
  car: CarInParking = <CarInParking>{
    carType: 'ניסן מיקרה 2017',
    carGearType: 'אוטומטי',
    lastWashDate: null,
    gasType: 'אוקטן 95',
    amountOfSeats: 5,
    isA2BCar: true,
    carCategory: 1,
  };

  @Input()
  userMode: UserMode;

  @Input()
  addressName: string;

  isShowDetailes = true;
  @Input()
  parking: Parking;
  isShowMoreDetails = false;

  @Output() sendDataToParent = new EventEmitter<boolean>();

  get CARS_IMAGES() {
    return AppGlobals.CARS_IMAGES;
  }

  getCarsImages(carType: string) {
    return AppGlobals.CARS_IMAGES[carType]
      ? AppGlobals.CARS_IMAGES[carType]
      : AppGlobals.CARS_IMAGES.NO_DATA;
  }

  constructor(private _globalService: GlobalService) { }

  ngOnInit() {
    console.log(this.checkIfIsEmployDrive());
  }

  toggleDetails() {
    this.isShowMoreDetails = !this.isShowMoreDetails;
  }

  closeDetails() {
    this.sendDataToParent.emit(false);
  }
  checkIfIsEmployDrive(): boolean {
    const now = new Date(Date.now());
    return (this.parking?.carsDetails && this.parking.carsDetails.filter(
      (c) =>
        c.timeSlots != null &&
        c.timeSlots.length > 0 &&
        c.timeSlots.some(
          (d) =>
            d.used != null &&
            d.used.length > 0 &&
            d.used.some(
              (w) =>
                new Date(w.start) < now &&
                new Date(w.end) > now
            )
        )
    ).length > 0);
  }

  getOfficeOrderTypeByEnum(): string {
    const i_1 = this.parking.carsDetails.findIndex(
      (c) =>
        c.timeSlots != null &&
        c.timeSlots.find(
          (s) => s.used?.some((t) => t.odt != null)
        ) != null
    );
    const i_2 = this.parking.carsDetails[i_1].timeSlots.findIndex(
      (d) => d.used != null && d.used.some((k) => k.odt != null)
    );
    const i_3 = this.parking.carsDetails[i_1].timeSlots[i_2].used.findIndex(
      (h) => h.odt != null
    );
    const odtType = this.parking.carsDetails[i_1].timeSlots[i_2].used[i_3].odt;

    switch (odtType) {
      case 0:
        return 'מוסך';
      case 1:
        return 'הרשמה';
      case 2:
        return 'תיקונים';
      case 3:
        return 'שונות';
      case 4:
        return "פנצ'ר";
      case 5:
        return 'שטיפה';
      case 6:
        return 'טסט';
      case 7:
        return 'תדלוק';
      default:
        return 'לא ידוע';
    }
  }

  navigateToCar() {
    this._globalService.navigateToCar(
      this.parking.earthLocation.lat,
      this.parking.earthLocation.lon
    );
  }
}

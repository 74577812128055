import { Component, Injector, OnInit } from '@angular/core';
import { overlayConfigFactory } from 'ngx-modialog-7';
import { Router } from '@angular/router';
import {
  ImproveCancellOrderComponent,
  ImproveCancellOrderModalContext,
} from '../../modals/improve-cancell-order/improve-cancell-order.component';
import { TimeSlotsPipe } from '../../pipes/time-slots.pipe';
import { CarInRentComponent } from '../../modals/car-in-rent/car-in-rent.component';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { from } from 'rxjs/observable/from';
import { UrlService } from '../../services/url.service';
import PageTitle from '../../models/page-title';
import { timeSlot } from '../../models/car';
import { ErrorResponse } from '../../models/error-response';
import { EmployeeOrder, OfficeOrderTypeDescriptionHeEnum, Order } from '../../models/order';
import { AlertService } from '../../services/alert.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { OrderService } from '../../services/order.service';
import { AppGlobals } from '../../shared/app-globals/app-globals';
import { currentDate } from '../../shared/function';
import { UserService } from '../../services/user.service';
import { HttpService } from '../../services/http.service';
import { FileHolder } from 'angular2-image-upload';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css'],
})
export class OrderListComponent implements OnInit {
  public OfficeOrderTypeDescriptionHeEnum: typeof OfficeOrderTypeDescriptionHeEnum = OfficeOrderTypeDescriptionHeEnum;

  isCurrentOrder = false;
  isShowHistory = false;
  getPriceDetails = false;
  isShowSmallFormat: boolean;
  selectedTab: number;

  orders: (EmployeeOrder | Order)[];
  selectedIndex: number;

  message: string;
  emptyOrders: boolean;
  orderToShow: Order[];

  endTime: Date = currentDate();
  startTime: Date = new Date(moment(currentDate()).add(-1, 'M').format());
  lastRangeSearch: timeSlot;
  images: string[] = [];

  get pageTitle(): PageTitle {
    return {
      title: 'ORDERS',
      previousUrl: '/menu',
    };
  }

  constructor(
    private _orderService: OrderService,
    public modal: Modal,
    private _router: Router,
    private _timeSlotPipe: TimeSlotsPipe,
    private _modalService: ModalService,
    private _alertService: AlertService,
    private _loadingService: LoadingService,
    private translate: TranslateService,
    private _userService: UserService,
    private httpService: HttpService,
    private _urlService: UrlService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    const orders = this.router.getCurrentNavigation()?.extras.state?.orders as Order[];
    if (orders) {
      this.selectedTab = 2;
      this.orders = orders
    }
  }
  ngOnInit() {
    this._orderService.isLastYearAndNextMonth = true;
  }

  get getOrderArray(): (Order | EmployeeOrder)[] {
    switch (this.selectedTab) {
      case 0:
        return this._orderService.futureOrder;
      case 1:
        return this._orderService.historyOrder;
      case 2:
        return this.orders;
      default:
        return this._orderService.futureOrder;
    }
  }

  get maxImagesSize(): number {
    return AppGlobals.IMAGES_DATA.MAX_SIZE_KB.REGISTER;
  }

  get CONTACT_DETAILS() {
    return AppGlobals.CONTACT_DETAILS;
  }

  loadOrders() {
    this.selectedIndex = 0;
    this._loadingService.startLoading();
    this._orderService.getAllOrders(this.isShowHistory).then(
      (orders: (EmployeeOrder | Order)[]) => {
        this.orders = orders;
        console.log('orders', this.orders);
        this._loadingService.stopLoading();
      },
      (error) => {
        console.error(error);
        this._alertService.error('אירעה שגיאה, נסה שנית');
        this._loadingService.stopLoading();
      }
    );
  }

  openOrderDetails(orderIndex: number): void {
    if (this.selectedIndex != orderIndex) this.selectedIndex = orderIndex;
    else this.selectedIndex = 0;
  }

  changeOrders(): void {
    this.isShowHistory = !this.isShowHistory;
    this.loadOrders();
  }

  showHistory(): void {
    this._loadingService.startLoading();
    const historyTimeSlot = <timeSlot>{
      atParking: {
        start: moment(this.startTime).format(),
        end: moment(this.endTime).format(),
      }
    };
    if (
      this.lastRangeSearch?.atParking.start == historyTimeSlot.atParking.start &&
      this.lastRangeSearch.atParking.end == historyTimeSlot.atParking.end
    ) {
      this._loadingService.stopLoading();
      return;
    }

    if (this.isRangeGraterThanMonth()) {
      this._modalService.confirm(
        'שים לב',
        "מס' הזמנות העבר שניתן להציג מוגבל. במקרה הצורך היעזר בטווח חיפוש על מנת לקבל את התוצאה הרצויה",
        'אישור',
        'ביטול',
        () => { },
      );
    }

    this._orderService
      .getHistoryOrders(historyTimeSlot, true)
      .then(() => {
        this.lastRangeSearch = historyTimeSlot;
        if (this.orders && this.orders.length == 0) {
          this.message =
            this.translate.currentLang == 'he'
              ? 'אין לך הזמנות קודמות'
              : 'You have no previous orders';
        } else {
          this.emptyOrders = false;
        }
      });
  }

  isRangeGraterThanMonth(): boolean {
    const monthDifference = moment(new Date(this.endTime)).diff(
      new Date(this.startTime),
      'months',
      true
    );
    console.log(monthDifference);
    return monthDifference > 6;
  }

  showFuture() {
    this.selectedTab = 0;
    this._orderService.getFutureOrders(true).then((orders) => {
      if (this.orders && this.orders.length == 0) {
        this.emptyOrders = true;
        this.message =
          this.translate.currentLang == 'he'
            ? 'אין לך הזמנות עתידיות'
            : 'You have no future orders';
      } else {
        this.emptyOrders = false;
      }
    });
  }

  editOrder() {
    this.orders = this._orderService.futureOrder;
    console.log(
      'this.orders[this.selectedIndex]',
      this.orders[this.selectedIndex]
    );
    this._orderService.changeCurrentOrder(
      Object.assign({}, this.orders[this.selectedIndex])
    );
    this._timeSlotPipe.currentOrderTimeSlot = this._orderService.OrderTimeSlot;
    this._router.navigate(['/order/stationsInMap']);
  }

  cancellOrder(): void {
    this.orders = this._orderService.futureOrder;
    this._orderService
      .getOrderById(this.orders[this.selectedIndex].id, true)
      .then((order) => {
        this.orders[this.selectedIndex] = order;
        this.modal.open(
          ImproveCancellOrderComponent,
          overlayConfigFactory(
            new ImproveCancellOrderModalContext(
              this.orders[this.selectedIndex],
              () => {
                this.orders[this.selectedIndex].isCancelled = true;
                this.loadOrders();
              }
            )
          )
        );
      });
  }

  showCarInRent(): void {
    this.selectedTab = 2;
    this.isCurrentOrder = true;
    this._orderService.getCurrentOrder(true).then((orders) => {
      if (orders) {
        this.emptyOrders = false;
        this.orders = orders;
      } else {
        this.emptyOrders = true;
        this.message =
          this.translate.currentLang == 'he'
            ? 'אין לך הזמנה פעילה'
            : 'you dont have an active order now';
        if (orders) {
          this.emptyOrders = false;
          this.orders = orders;
        }
      }
    });
  }

  editCurrentOrder(num: number) {
    this.modalService.open(CarInRentComponent, {
      centered: true, injector: Injector.create(
        {
          providers: [
            {
              provide: Order,
              useValue: this.orders[num],
            }
          ],
        }
      ),
    });
  }

  sendTravelDetails(orderId: number) {
    this._orderService.sendTravelDetails(orderId).subscribe(
      () => {
        const message =
          this.translate.currentLang == 'he'
            ? 'פירוט נסיעה נשלח בהצלחה למייל הרשום במערכת!'
            : 'Order details have been sent successfully to the Email listed in the system!';

        this._alertService.success(message);
      },
      (error: ErrorResponse) => {
        if (error.CustomErrorMessage)
          this._alertService.error(error.CustomErrorMessage);
        else console.error(error);
      }
    );
  }

  sendEmail(orderId: number) {
    console.log(orderId);
    const mail = document.createElement('a');
    mail.href =
      'mailto:' +
      this.CONTACT_DETAILS.MAIL +
      '?subject=פניה לגבי הזמנה ' +
      orderId;
    mail.click();
  }

  minutsAfterOrder(order: Order) {
    const diff = new Date(Date.now()).getTime() - order.endTime.getTime();
    const minutes = Math.floor(diff / 1000 / 60);
    return minutes <= 10;
  }

  isIn24HoursOfOrder(index): boolean {
    const order = this.getOrderArray[index];
    const duration = moment.duration(moment().diff(moment(order.endTime)));
    const hours = Math.abs(duration.asHours());
    return hours <= 24;
  }

  markAsWashed(index) {
    console.log(index);
    const order = this.getOrderArray[index];
    const now = moment.utc().local().format('YYYY-MM-DDTHH:mm:ss');
    const body = { time: now, carNumber: order.car.carNumber };
    this.httpService
      .post(this._urlService.nodeUrl() + 'api/wash', body)
      .then((response) => {
        console.log(response);
        this._alertService.success('רכב סומן כנשטף');
      })
      .catch((error) => {
        console.log(error);
        this._alertService.error('אירעה שגיאה! נסה שוב מאוחר יותר');
      });
  }

  onUploadFinished(event: FileHolder, isMustPicture: string) {
    console.log(event);
    console.log(isMustPicture);
    this.images.push(event.src);
  }

  onRemoved() {
    this.images = [];
  }

  sendImage(order: Order) {
    console.log(order);

    const files: { data: string, originalname: string, mimetype: string }[] = [];

    this.images.forEach((image, index) => {
      const type = image.substring('data:'.length, image.indexOf(';base64'));
      files.push({
        data: image.split(',')[1],
        originalname: `image_${index}_${order.id}`,
        mimetype: type,
      });
    });

    console.log(files);

    const body = {
      userId: this._userService.user.identifier.toString(),
      orderId: order.id.toString(),
      carNumber: order.car.carNumber,
      orderType: order['officeDriveType'],
      uploadType: 'base64',
      files: files,
    };

    console.log(body);

    if (this.images.length < 1) {
      this._alertService.error('אי אפשר לשלוח ללא תמונות');
      return;
    }

    from(
      this.httpService.post(this._urlService.nodeUrl() + 'api/files/add', body)
    ).subscribe(
      (res) => {
        console.log(res);
        this._alertService.success('פעולתך בוצעה');
        //this.pictures = [];
      },
      (err) => {
        console.log(err);
        this._alertService.error(err.CustomErrorMessage.value);
      }
    );
  }
}

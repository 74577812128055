import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { currentDate, newDate } from '../shared/function';
import { Used } from '../models/car';
import { AppGlobals } from '../shared/app-globals/app-globals';

@Pipe({
  name: 'carAvailability',
})
export class CarAvailabilityPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) { }

  transform(value: Used[]): string[] {
    if (!value || !value.length) {
      return [];
    }

    const suggestionsArray: string[] = [];

    let lastEnd = '';

    value.forEach((v) => {
      let message: string;

      // if the suggestion is for less the hour ahead, not show it:
      if (
        newDate(v.start).getTime() - AppGlobals.TIMES.HOURS_IN_TIME >
        currentDate().getTime()
      ) {
        if (lastEnd) {
          message =
            ' נסה להתחיל אחרי ' +
            this.datePipe.transform(newDate(lastEnd), 'dd/MM/yy HH:mm') +
            ' ולסיים לפני ';
        } else {
          message = ' נסה לסיים לפני ';
        }
        message += this.datePipe.transform(newDate(v.start), 'dd/MM/yy HH:mm');
        suggestionsArray.push(message);
      }

      lastEnd = v.end;
    });

    if (lastEnd) {
      suggestionsArray.push(
        '  נסה להתחיל אחרי ' +
        this.datePipe.transform(newDate(lastEnd), 'dd/MM/yy HH:mm')
      );
    }
    return suggestionsArray;
  }
}
